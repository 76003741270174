import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as AverageTaxationIcon } from '../../../../shared/assets/icons/taxations/average.svg';
import { ReactComponent as HighTaxationIcon } from '../../../../shared/assets/icons/taxations/high.svg';
import { ReactComponent as LowTaxationIcon } from '../../../../shared/assets/icons/taxations/low.svg';

/**
 * Generate configuration checkbox data
 */
export const useGenerateConfigCheckboxData = () => {
  const { productConfigTemplates: configurations, selectedDeviceConfig } =
    useSelector((state) => state.createTreatmentWizard);

  return useMemo(
    () =>
      configurations
        ?.map((config) => {
          switch (config?.name) {
            case 'Basic':
              return {
                id: config.id,
                order: 1,
                Icon: <LowTaxationIcon className='taxation-icon low' />,
                title: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}`,
                desc: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}-description`,
                checked: selectedDeviceConfig
                  ? selectedDeviceConfig.id === config.id
                  : false,
              };
            case 'Advanced':
              return {
                id: config.id,
                order: 2,
                Icon: <AverageTaxationIcon className='taxation-icon avarage' />,
                title: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}`,
                desc: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}-description`,
                checked: selectedDeviceConfig
                  ? selectedDeviceConfig.id === config.id
                  : false,
              };
            case 'Challenging':
              return {
                id: config.id,
                order: 3,
                Icon: <HighTaxationIcon className='taxation-icon high' />,
                title: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}`,
                desc: `devices.templates.${(
                  config?.name || ''
                ).toLowerCase()}-description`,
                checked: selectedDeviceConfig
                  ? selectedDeviceConfig.id === config.id
                  : false,
              };
          }
        })
        .sort((a, b) => a.order - b.order),
    [configurations, selectedDeviceConfig],
  );
};
