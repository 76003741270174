import { Box, Skeleton, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { Transition, TransitionGroup } from 'react-transition-group';
import { ReactComponent as DefaultDeviceIcon } from '../../../../shared/assets/icons/devices/default-device.svg';

import { useActions } from '../../../../hooks/useActions';
import { deviceActions } from '../../../../store/actions/device.actions';
import { AppImage } from '../../../UI/AppImage/AppImage';
import AutocompleteUi from '../../../UI/Autocomplete/Autocomplete';
import { WizardContentBox } from '../../../UI/WizardContentBox/WizardContentBox';
import { useWizardDeviceError } from '../hooks/useWizardDeviceError';
import ProductConfiguration from './ProductConfiguration/ProductConfiguration';
import { devicesStepStyles } from './style';
import { getProductIdsConsts } from '../../../../utils/wizard-helper';

const contentBoxes = {
  selectDevice: true,
  selectCongif: false,
};

function SelectDevice({
  getAllDevices,
  devices: devicesSelector,
  products: productsReducer,
  t,
}) {
  const { deviceSelect } = devicesStepStyles;

  const { devices, isLoading } = devicesSelector;
  const {
    selectedDevice,
    error,
    productConfigTemplates: configurations,
  } = useSelector((state) => state.createTreatmentWizard);
  const { selectedProduct } = useSelector(
    (state) => state.createTreatmentWizard,
  );
  const { setDevice, setNextButton } = useActions();

  const [autocompleteValue, setAutocompleteValue] = useState(
    selectedDevice
      ? { text: selectedDevice.name, value: selectedDevice.id }
      : null,
  );
  const [contentBoxActive, setContentBoxActive] = useState(contentBoxes);

  const { EMDR_VrId } = getProductIdsConsts(productsReducer.products);
  const showConfig =
    EMDR_VrId === selectedProduct.id && !!configurations.length;

  /**
   * Handle Autocomplete change event
   * (option selection, clear value, close...)
   * @param event
   * @param value selected option value
   */
  const handleAutocompleteChange = (event, value) => {
    if (!value && selectedDevice) setDevice(null);

    setDevice(devices.find((device) => device.id === value?.value));
    setAutocompleteValue(value);
  };

  /**
   * Handle Autocomplete open event
   * and fetching all devices
   */
  const handleAutocompleteOpen = () => {
    setContentBoxActive(() => ({ selectDevice: true, selectCongif: false }));
    if (devices.length === 0) getAllDevices(false);
  };

  /**
   * Image loading fallback
   */
  const fallback = <Skeleton variant='rounded' width='100%' height='100%' />;

  /**
   * Image loading error fallback
   */
  const errorFallback = <DefaultDeviceIcon className='default' />;

  /**
   * Device image with transition animation
   */
  const deviceImgContent = (
    <TransitionGroup className='transition-group'>
      <Transition
        key={selectedDevice?.id}
        timeout={300}
        mountOnEnter
        unmountOnExit
        appear={true}
      >
        {(state) => (
          <Box className={`img-wrapper slide-in-${state}`}>
            <AppImage
              fallback={fallback}
              errorFallback={errorFallback}
              src={selectedDevice?.deviceprofile?.imageUrl}
              className='img'
              alt='device image'
            />
          </Box>
        )}
      </Transition>
    </TransitionGroup>
  );

  /**
   * Set error if device isn't selected
   */
  useWizardDeviceError();

  /**
   * Checks if error exists and disable or enable next button
   */
  useEffect(() => {
    if (error) setNextButton(false);
    else setNextButton(true);
  }, [error, setNextButton]);

  useEffect(() => {
    if (devices.length === 0) getAllDevices(false);
  }, []);

  return (
    <Box sx={deviceSelect} className='step-container'>
      <Box className='device-selection-row'>
        <Box className='device-image'>{deviceImgContent}</Box>
        <WizardContentBox
          className='device-selection'
          active={contentBoxActive.selectDevice}
        >
          <Typography variant='h3' className='wizard-box-title'>
            {t('create-treatment-wizard.select-device.device-dropdown.title')}
          </Typography>
          <Typography variant='h5' className='wizard-box-subtitle'>
            {t(
              'create-treatment-wizard.select-device.device-dropdown.subtitle',
            )}
          </Typography>
          <AutocompleteUi
            label={t(
              'create-treatment-wizard.select-device.device-dropdown.label',
            )}
            placeholder={t(
              'create-treatment-wizard.select-device.device-dropdown.placeholder',
            )}
            autocompleteValue={autocompleteValue}
            onAutocompleteChangeHandler={handleAutocompleteChange}
            onAutocompleteOpenHandler={handleAutocompleteOpen}
            loading={isLoading}
            options={devices.map((device) => ({
              text: device.name,
              value: device.id,
            }))}
          />
        </WizardContentBox>
      </Box>
      {showConfig && (
        <ProductConfiguration
          setContentBoxActive={setContentBoxActive}
          isActive={contentBoxActive.selectCongif}
        />
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllDevices: deviceActions.getAllDevices,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(memo(SelectDevice)),
);
