import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import ChainedBackend from 'i18next-chained-backend';
import BASEURL from './utils/config';

import translationEN from './locales/en.json';
import translationNL from './locales/nl.json';
import translationDE from './locales/de.json';

// the fallback translations
const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector)
  .use(ChainedBackend)
  .init({
    backend: {
      backends: [
        // Comment to work with local translation files
        HttpBackend,
        resourcesToBackend(resources),
      ],
      // for all available options read the backend's repository readme file
      backendOptions: [
        {
          loadPath: `${BASEURL}/v2/translations?lang={{lng}}`,
        },
      ],
    },
    detection: {
      order: [
        'localStorage',
        'navigator',
        'cookie',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    caches: ['localStorage', 'cookie'],
    // resources,
    // lng: 'nl',
    // debug: true,
    fallbackLng: 'nl', // use nl if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, _format, _lng) => {
        const newValue = value.toLowerCase();
        return newValue.charAt(0).toUpperCase() + newValue.slice(1);
      },
    },
  });

export default i18n;
