const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getAllSelectedValuesByVariable = (variables) => {
  if (variables.length === 0) {
    return [];
  }

  return variables.map((item) => ({
    name: item.name,
    value:
      item.selectedValue === null || item.selectedValue === undefined
        ? `${item.value}`
        : `${item.selectedValue}`,
    id: item.id,
  }));
};

const setDefaultValuesForVariables = (variables) =>
  variables.map((variable) => {
    const uiConfig = variable.ui_configuration;
    switch (uiConfig.type) {
      case 'slider':
        variable.selectedValue = parseFloat(uiConfig.slider_min);
        break;
      case 'toggle':
        variable.selectedValue = false;
        break;
      default:
        break;
    }
    return variable;
  });

export {
  isJsonString,
  getAllSelectedValuesByVariable,
  setDefaultValuesForVariables,
};
