import { useEffect, useState } from 'react';
import { userService } from '../services/login';

export const useValidateAccessToken = (logout) => {
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  const checkIfAuthTokenIsValid = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const { userId, id } = JSON.parse(token);
        const { data: isValid } = await userService.validateAccessToken(
          userId,
          id,
        );
        if (!isValid) {
          localStorage.removeItem('token');
          logout('?isAuthTimeout=true');
        }
      }
    } catch (e) {
      console.error('[AUTH CHECK FAILED]: Failed to check the time to live.');
    }
  };

  useEffect(() => {
    checkIfAuthTokenIsValid()
      .then(() => setIsValidatingToken(false))
      .catch(() => setIsValidatingToken(false));
  }, []);
  return { isValidatingToken };
};
