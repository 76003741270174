import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as SettingsIcon } from '../../../../shared/assets/icons/sidenav/settings.svg';
import { ReactComponent as TaskIcon } from '../../../../shared/assets/icons/advanced-settings/task.svg';
import { ReactComponent as SphereIcon } from '../../../../shared/assets/icons/advanced-settings/sphere.svg';
import { ReactComponent as ReactionIcon } from '../../../../shared/assets/icons/advanced-settings/reaction.svg';
import { ReactComponent as AudioIcon } from '../../../../shared/assets/icons/advanced-settings/audio.svg';
import { ADVANCED_SETTINGS_GROUPS } from '../../../../utils/wizard-helper';

/**
 * Generate Advenced settings groups with group settings data
 */
export const useAdvancedSettingsGroupsData = () => {
  const { selectedProduct } = useSelector(
    (state) => state.createTreatmentWizard,
  );

  return useMemo(
    () =>
      Object.values(
        selectedProduct.variables.reduce((items, variable) => {
          const { group } = variable.ui_configuration;
          const noGroup = 'no group';
          if (group) {
            items[group] = items[group] || {
              groupName: group,
              variables: [],
              Icon: mapGroupsIconst(group),
            };
            // TODO refactor variables config on backend
            // if (variable.name === 'task_reaction_increment') {
            //   items[group].variables.push({
            //     ...variable,
            //     ui_configuration: {
            //       ...variable.ui_configuration,
            //       values: [
            //         { value: '0.01', label: '1%' },
            //         { value: '0.03', label: '3%' },
            //         { value: '0.05', label: '5%' },
            //         { value: '0.07', label: '7%' },
            //         { value: '0.09', label: '9%' },
            //       ],
            //       order: 2,
            //     },
            //   });
            items[group].variables.push(variable);
          } else {
            items[noGroup] = items[noGroup] || {
              groupName: '',
              variables: [],
            };
            items[noGroup].variables.push(variable);
          }
          return items;
        }, {}),
      ),
    [selectedProduct],
  );
};

const mapGroupsIconst = (groupName) => {
  switch (groupName) {
    case ADVANCED_SETTINGS_GROUPS.GENERAL:
      return <SettingsIcon className='general' />;
    case ADVANCED_SETTINGS_GROUPS.TASK:
      return <TaskIcon />;
    case ADVANCED_SETTINGS_GROUPS.SPHERE:
      return <SphereIcon />;
    case ADVANCED_SETTINGS_GROUPS.REACTION:
      return <ReactionIcon />;
    case ADVANCED_SETTINGS_GROUPS.AUDIO:
      return <AudioIcon />;
  }
};
