import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { classNames } from '../../../utils';
import { CheckboxUi } from '../Checkbox/Checkbox';
import { checkboxCardStyles } from './style';

const CheckboxCard = (props) => {
  const {
    checkboxItem,
    onCardSelectHandler,
    type = 'row',
    disabled,
    t,
    className,
    handleFromCheckbox = false,
  } = props;
  const { Icon, title, desc, checked } = checkboxItem;
  const { checkboxCard } = checkboxCardStyles;
  const [displayValue, setDisplayValue] = useState(checked);

  const onChange = useCallback(() => {
    if (!handleFromCheckbox) onCardSelectHandler(checkboxItem);
    else {
      setDisplayValue((prev) => !prev);
      onCardSelectHandler(!displayValue);
    }
  }, [
    onCardSelectHandler,
    setDisplayValue,
    displayValue,
    checkboxItem,
    handleFromCheckbox,
  ]);

  useEffect(() => {
    setDisplayValue(checked);
  }, [checked]);

  const mods = {
    column: type == 'column',
    disabled,
    checked: handleFromCheckbox ? displayValue : checked,
  };

  return (
    <Card
      sx={checkboxCard}
      className={classNames(className, mods)}
      onClick={onChange}
    >
      <CardActionArea>
        <Box className='icon'>{Icon}</Box>
        <CardContent>
          <Typography className='card-title' variant='h5'>
            {t(`${title}`)}
          </Typography>
          <Typography className='desc' variant='h6'>
            {t(`${desc}`)}
          </Typography>
        </CardContent>
        <CheckboxUi
          checked={handleFromCheckbox ? displayValue : checked}
          className='checkbox'
        />
      </CardActionArea>
    </Card>
  );
};

export default withTranslation()(memo(CheckboxCard));
