import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames, getResponsiveSize } from '../../../utils';

import AutocompleteSelectHints from '../AutocompleteSelectHints/AutocompleteSelectHints';
import { ButtonUi } from '../Button/Button';
import { selectStyles } from './style';

function SelectUI(props) {
  const {
    idPrefix,
    label,
    size = undefined,
    selectValue,
    onChangeHandler,
    options,
    disabled,
    name,
    width = getResponsiveSize(428), // 428px
    height = getResponsiveSize(53), // 53px
    hideHints = false,
    renderValue = (value) => value,
    dropdownDirection = 'default', // top | bottom
  } = props;
  const [displayValue, setDisplayValue] = useState(selectValue.toString());
  const [open, setOpen] = useState(false);

  /**
   * Select dropdown direction
   */
  const direction = useMemo(() => {
    switch (dropdownDirection) {
      case 'bottom':
        return {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        };
      case 'top':
        return {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        };

      default:
        return {};
    }
  }, [dropdownDirection]);

  /**
   * Handle open select dropdown
   */
  const setSelectOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  /**
   * Handle close select dropdown
   */
  const setSelectClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  /**
   * Handle select dropdown change event and set selected value
   * @param event native event
   */
  const onSelectChange = (event) => {
    setDisplayValue(event.target.value);
    onChangeHandler({
      variable: event.target.name,
      value: event.target.value,
    });
    setSelectClose();
  };

  useEffect(() => {
    setDisplayValue(selectValue.toString());
  }, [selectValue]);

  return (
    <FormControl
      disabled={disabled}
      sx={selectStyles.selectBox}
      size={size}
      className={classNames('', { open })}
    >
      {!!label && <InputLabel id={`${idPrefix}-label`}>{label}</InputLabel>}
      <Select
        open={open}
        labelId={`${idPrefix}-select-label`}
        id={`${idPrefix}-select`}
        value={displayValue}
        label={label}
        inputProps={{
          name,
        }}
        onOpen={setSelectOpen}
        onClose={setSelectClose}
        onChange={onSelectChange}
        sx={{ width, height }}
        MenuProps={{ ...direction, ...selectStyles.scrollStyle }}
        renderValue={renderValue}
      >
        {options &&
          options.map((option, idx) => (
            <MenuItem key={idx} value={option.value}>
              {option.text}
              {option?.icon}
            </MenuItem>
          ))}
        {!hideHints && <AutocompleteSelectHints className='hints' />}
      </Select>
      <ButtonUi
        isIconBtn
        className={classNames('chevron', {})}
        size='small'
        onClickHandler={setSelectOpen}
        disabled={disabled}
      >
        <FontAwesomeIcon icon='chevron-down' />
      </ButtonUi>
    </FormControl>
  );
}
export default memo(SelectUI);
