import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { billingService } from '../../../services/billing';
import ModalWrapper from '../../ModalWrapper';

const LicenseModal = ({
  i18n,
  authentication: { foundUser, practitioner, organizationId },
  closeModal,
}) => {
  const [licensePlans, setLicensePlans] = useState([]);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState();

  const fetchLicensePlans = async () => {
    try {
      const result = await billingService.getLicensePlans();
      setLicensePlans(result.data);
      setSelectedLicense(result.data[0]);
    } catch {
      // TODO: Handle error
    }
  };

  const priceLocale = useMemo(() => {
    switch (i18n.language) {
      case 'nl':
        return 'nl-nl';
      case 'de':
        return 'de-de';
      case 'en':
        return 'en-gb';
    }
  }, [i18n.language]);

  const parseStringToNumberFormat = (numberString) =>
    parseFloat(numberString).toLocaleString(priceLocale, {
      minimumFractionDigits: 2,
    });

  const handleContinuePayment = async () => {
    setIsRedirecting(true);
    const checkoutUrl = await createNewSubscriptionPayment();
    setTimeout(() => {
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        setIsRedirecting(false);
      }
    }, 5000);
  };

  const createNewSubscriptionPayment = async () => {
    try {
      const body = {
        chargeeUserId: foundUser.id,
        organizationId: undefined, // Check this
        subscriberUserIds: [foundUser.id], // TODO: Select multiple users
        licensePlanId: selectedLicense?.id,
      };
      const createdLicense = await billingService.createSubscription(body);
      return createdLicense?.data?.checkoutUrl;
    } catch (e) {
      console.error('Failed to create subscription.');
      return '';
    }
  };

  const handleLicenseSelect = (event) => {
    const selectedValueId = parseInt(event.target.value);
    setSelectedLicense(
      licensePlans.find((licensePlan) => licensePlan.id === selectedValueId),
    );
  };

  useEffect(() => {
    fetchLicensePlans();
  }, []);

  return (
    <ModalWrapper
      title={i18n.t('billing.license.create-title')}
      modalIsOpen={true}
      width='80%'
      closeModal={closeModal}
    >
      <div className='flex items-center justify-between h-full'>
        <div className='bg-white rounded p-4 w-3/4 mr-8'>
          <div className='mt-1'>
            <span className='text-gray-700'>
              {i18n.t('billing.license.payment-for')}
            </span>
            <div className='mt-2'>
              <label className='inline-flex items-center'>
                <input
                  type='radio'
                  className='form-radio'
                  name='accountType'
                  value='personal'
                  checked
                />
                <span className='ml-2'>{i18n.t('billing.license.myself')}</span>
              </label>
              {/* <label className='inline-flex items-center ml-6'>
                <input
                  type='radio'
                  disabled
                  className='form-radio'
                  name='accountType'
                  value='business'
                />
                <span className='ml-2'>
                  {i18n.t('billing.license.multiple')}
                </span>
              </label> */}
            </div>
          </div>
          <label className='block mt-4'>
            <span className='text-gray-700'>
              {i18n.t('billing.license.license-plan')}
            </span>
            <select
              className='form-select block w-full mt-1 border border-gray-400 p-1 rounded-lg'
              onChange={handleLicenseSelect}
            >
              {licensePlans.map((licensePlan) => (
                <option value={licensePlan.id} key={licensePlan.id}>
                  {licensePlan.name} -{' '}
                  {i18n.t(`billing.license.${licensePlan.frequency}`)} (€{' '}
                  {parseStringToNumberFormat(licensePlan.price)})
                </option>
              ))}
            </select>
          </label>
          {isRedirecting && (
            <div className='mt-4 text-blue-600'>
              {i18n.t('billing.license.redirect-text')}
            </div>
          )}
          <div className='flex items-center justify-end mt-4'>
            <button
              className='inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 mr-4'
              disabled={isRedirecting}
              onClick={closeModal}
            >
              {i18n.t('billing.license.cancel-payment')}
            </button>
            <button
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline '
              type='button'
              disabled={isRedirecting}
              onClick={handleContinuePayment}
            >
              {isRedirecting && (
                <FontAwesomeIcon
                  size='lg'
                  className='mr-2'
                  icon='spinner'
                  spin={true}
                />
              )}
              {i18n.t('billing.license.continue-payment')}
            </button>
          </div>
        </div>
        <div className='bg-white rounded shadow-lg p-4 w-1/4 h-full min-h-full'>
          <h2 className='bg-grey-lightest font-bold mt-2 text-underline'>
            {i18n.t('billing.license.summary')}
          </h2>
          <div className='py-3' role='alert'>
            <p className='font-bold'>
              {selectedLicense?.name} -{' '}
              {i18n.t(`billing.license.${selectedLicense?.frequency}`)}
            </p>
            <p className='font-semibold'>
              {i18n.t('billing.license.price')}: €
              {parseStringToNumberFormat(selectedLicense?.price)}
            </p>
            <p className='text-sm mt-1'>{selectedLicense?.description}</p>
            <p className='text-sm mt-1 font-semibold'>
              {selectedLicense?.user && selectedLicense?.device
                ? i18n.t('billing.license.includes-remote')
                : ''}
            </p>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LicenseModal),
);
