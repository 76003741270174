import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { theme, laptopRatio } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const CustomSlider = styled(Slider)({
  height: getResponsiveSize(4), // 4px
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#5D95A9',
    borderRadius: getResponsiveSize(8), // 8px
    color: theme.palette.myPrimary.white,
    top: '-100%',
    fontSize: theme.typography.fs19,
    padding: `${getResponsiveSize(4)} ${getResponsiveSize(12)}`, // 4px 12px
    '&:before': {
      width: getResponsiveSize(8), // 8px
      height: getResponsiveSize(8), // 8px
    },
  },
  // Laptop screen
  [theme.breakpoints.media.laptop]: {
    height: getResponsiveSize(4, laptopRatio), // 4px
    '& .MuiSlider-valueLabel': {
      borderRadius: getResponsiveSize(6, laptopRatio), // 6px
      fontSize: getResponsiveSize(15, laptopRatio), // 15px
      padding: `${getResponsiveSize(4, laptopRatio)} ${getResponsiveSize(
        12,
        laptopRatio,
      )}`, // 4px 12px
      '&:before': {
        width: getResponsiveSize(6, laptopRatio), // 6px
        height: getResponsiveSize(6, laptopRatio), // 6px
      },
    },
  },
});

export const rangeInptuStyle = {
  rangeInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: getResponsiveSize(20), // 20px
    alignItems: 'center',
    position: 'relative',
    '& .MuiSlider-root': {
      mb: 0,
      willChange: 'left, right',
      color: theme.palette.propVars.sliderBg,
      borderRadius: theme.palette.propVars.borderRadius,
      py: getResponsiveSize(13), // 13px
      '& .MuiSlider-markLabel': {
        fontSize: theme.typography.fs19,
        color: theme.palette.neutral.grey70,
        top: `calc(${getResponsiveSize(19)} + ${getResponsiveSize(20)}) `,
        '& .first': {
          position: 'absolute',
          fontFamily: theme.typography.mainFontFamily,
        },
        '& .last': {
          fontFamily: theme.typography.mainFontFamily,
          position: 'absolute',
          right: '50%',
        },
      },
      '& .MuiSlider-mark': {
        backgroundColor: theme.palette.myPrimary.darkAquamarine,
        '&.MuiSlider-markActive': {
          backgroundColor: '#2CABD8',
        },
      },
      '& .MuiSlider-thumb': {
        width: getResponsiveSize(20), // 20px
        height: getResponsiveSize(20), // 20px
        backgroundColor: theme.palette.myPrimary.darkAquamarine,
        boxShadow: `0px ${getResponsiveSize(4)} ${getResponsiveSize(
          4,
        )} rgba(0, 0, 0, 0.25)`,
        '&:hover': {
          boxShadow: `0px 0px 0px ${getResponsiveSize(8)} ${
            theme.palette.propVars.sliderBg
          }`,
        },
        '&.Mui-active': {
          boxShadow: `0px 0px 0px ${getResponsiveSize(14)} ${
            theme.palette.propVars.sliderBg
          }`,
        },
      },
      '& .MuiSlider-rail': {
        borderRadius: '100px',
      },
      '& .MuiSlider-track': {
        color: theme.palette.myPrimary.darkAquamarine,
        boxShadow: `0px ${getResponsiveSize(4)} ${getResponsiveSize(
          4,
        )} rgba(23, 96, 122, 0.25)`,
      },
    },
    '& .slider-labels': {
      display: 'flex',
      position: 'absolute',
      top: getResponsiveSize(43),
      '& .mark-label': {
        fontSize: theme.typography.fs19,
        color: theme.palette.neutral.grey70,
        fontFamily: theme.typography.mainFontFamily,
        position: 'absolute',
        cursor: 'pointer',
        userSelect: 'none !important',
        '&.first': {
          left: '0',
        },
        '&.last': {
          left: 'unset',
          right: '0',
        },
      },
    },
    '& .error-wrapper': {
      position: 'relative',
      '& .error.visible': {
        visibility: 'visible',
        // opacity: '1',
        position: 'absolute',
        padding: getResponsiveSize(5.75), // 5.75px
        minWidth: getResponsiveSize(255), // 255px
        width: 'auto',
        bottom: '100%',
        left: '50%',
        right: 'unset',
        '& .MuiTypography-root': {
          fontSize: theme.typography.fs19,
        },
      },
    },
    // Animations
    '& .slide-in-entering': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-entered': {
      opacity: 1,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, -20%)',
      },
    },
    '& .slide-in-exiting': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
      '&.slide-top': {
        transform: 'translate(-50%, 10%)',
      },
    },
    '& .slide-in-exited': {
      opacity: 0,
      transition: 'all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      gap: getResponsiveSize(16, laptopRatio), // 20px
      '& .MuiSlider-root': {
        py: getResponsiveSize(13), // 13px
        '& .MuiSlider-markLabel': {
          fontSize: getResponsiveSize(15, laptopRatio), // 15px
          top: `calc(${getResponsiveSize(
            15,
            laptopRatio,
          )} + ${getResponsiveSize(16, laptopRatio)}) `,
        },
        '& .MuiSlider-thumb': {
          width: getResponsiveSize(16, laptopRatio), // 16px
          height: getResponsiveSize(16, laptopRatio), // 16px
          boxShadow: `0px ${getResponsiveSize(
            3,
            laptopRatio,
          )} ${getResponsiveSize(3, laptopRatio)} rgba(0, 0, 0, 0.25)`,
          '&:hover': {
            boxShadow: `0px 0px 0px ${getResponsiveSize(6, laptopRatio)} ${
              theme.palette.propVars.sliderBg
            }`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px ${getResponsiveSize(9, laptopRatio)} ${
              theme.palette.propVars.sliderBg
            }`,
          },
        },
        '& .MuiSlider-track': {
          boxShadow: `0px ${getResponsiveSize(
            3,
            laptopRatio,
          )} ${getResponsiveSize(3, laptopRatio)} rgba(23, 96, 122, 0.25)`,
        },
      },
      '& .slider-labels': {
        top: getResponsiveSize(37, laptopRatio),
        '& .mark-label': {
          fontSize: getResponsiveSize(15, laptopRatio), // 15px
        },
      },
      '& .error-wrapper': {
        '& .error.visible': {
          padding: getResponsiveSize(3.75, laptopRatio), // 5.75px
          minWidth: getResponsiveSize(180, laptopRatio), // 255px
          '& .MuiTypography-root': {
            fontSize: getResponsiveSize(14, laptopRatio), // 15px
          },
        },
      },
    },
  },
  inputStyle: {
    backgroundColor: '#17607A1F',
    width: getResponsiveSize(46.5), // 46.5px
    borderRadius: theme.palette.propVars.borderRadius,
    height: getResponsiveSize(40.5), // 40.5px
    p: getResponsiveSize(4.5), // 4.5px
    '& input::selection': {
      backgroundColor: '#7FA8B7',
    },
    '&.Mui-focused': {
      '&:after': {
        content: 'none',
      },
    },

    '& .MuiInput-input': {
      textAlign: 'center',
      p: 0,
      fontSize: `${theme.typography.fs19} !important`,
    },
    '&:before': {
      content: 'none',
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      width: getResponsiveSize(38, laptopRatio), // 38px
      height: getResponsiveSize(32, laptopRatio), // 32px
      borderRadius: getResponsiveSize(8, laptopRatio), // 32px
      p: getResponsiveSize(3, laptopRatio), // 3px
      '& .MuiInput-input': {
        fontSize: `${getResponsiveSize(15, laptopRatio)} !important`, // 15px
      },
    },
  },
};
