import { laptopRatio, mobileRatio, theme } from '../../../theme';
import { getResponsiveSize } from '../../../utils';

export const selectStyles = {
  position: 'relative',
  selectBox: {
    '& .MuiInputBase-root': {
      borderRadius: theme.palette.propVars.borderRadius, // 12px
      background: theme.palette.myPrimary.white,
      transition: '.8s all ease',
      '& .MuiSelect-select': {
        color: theme.palette.neutral.grey70,
        fontSize: theme.typography.fs19,
        py: getResponsiveSize(12), // 12px,
        pl: getResponsiveSize(24), // 24px,
        pr: `${getResponsiveSize(39)} !important`, // 39px
        lineHeight: getResponsiveSize(29), // 29px,
        textTransform: 'capitalize',
      },
      '& fieldset': {
        borderColor: theme.palette.myPrimary.lightBlue100,
      },
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
      '&.Mui-focused, &.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.myPrimary.lightBlue100,
        },
      },
    },
    '& .chevron': {
      width: getResponsiveSize(38), // 38px,
      height: getResponsiveSize(38), // 38px,
      zIndex: 10,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: `calc(${getResponsiveSize(38)} * 0.5)`,
      ...theme.palette.propVars.easeInOutTransition,
      '& svg': {
        color: theme.palette.myPrimary.lightBlue70,
        fontSize: getResponsiveSize(23), // 23px,
      },
    },
    '&.open': {
      '& .chevron': {
        transform: 'translateY(-50%) rotate(180deg)',
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .MuiInputBase-root': {
        height: getResponsiveSize(49, laptopRatio), // 49px,
        width: getResponsiveSize(325, laptopRatio), // 325px,
        '& .MuiSelect-select': {
          color: theme.palette.neutral.grey70,
          fontSize: getResponsiveSize(14, laptopRatio), // 14px,
          py: getResponsiveSize(13.5, laptopRatio), // 13.5px,
          pl: getResponsiveSize(21, laptopRatio), // 21px,
          pr: `${getResponsiveSize(39, laptopRatio)} !important`, // 39px
          lineHeight: '1.5',
        },
      },
      '& .chevron': {
        width: getResponsiveSize(28, laptopRatio), // 28px,
        height: getResponsiveSize(28, laptopRatio), // 28px,
        right: `calc(${getResponsiveSize(28, laptopRatio)} * 0.5)`,
        p: 0.25,
        '& svg': {
          fontSize: getResponsiveSize(16, laptopRatio), // 16px,
        },
      },
    },
  },
  scrollStyle: {
    PaperProps: {
      sx: {
        margin: `${getResponsiveSize(8)} auto`, // 8px auto
        borderRadius: theme.palette.propVars.borderRadius, // 12px
        border: '1px solid #2392BA',
        '& .MuiList-root': {
          maxHeight: getResponsiveSize(240), // 240px
          minWidth: getResponsiveSize(440), // 440px
          py: getResponsiveSize(8), // 8px
          mb: getResponsiveSize(52), // 52px
          '& .MuiMenuItem-root': {
            color: theme.palette.neutral.grey70,
            fontSize: theme.typography.fs16,
            fontFamily: theme.typography.mainFontFamily,
            borderRadius: theme.palette.propVars.borderRadius,
            transition: 'all .3s ease-in-out',
            mx: getResponsiveSize(12), // 12px,
            textTransform: 'capitalize',
            minHeight: getResponsiveSize(48), // 48px,
            padding: `${getResponsiveSize(6)} ${getResponsiveSize(16)}`, // 6px 16px
            alignItems: 'center',
            gap: 0.5,
            ...theme.palette.propVars.regularFontWeight,
            '&[aria-selected="true"]': {
              background: 'transparent',
            },
            '&:hover, &.Mui-focused': {
              background: theme.palette.myPrimary.lightBlue20,
            },
          },
          '& .hints': {
            width: '100%',
            border: 'none',
            bottom: `calc(${getResponsiveSize(52)} * -1)`, // -52px
          },
          '&::-webkit-scrollbar': {
            width: getResponsiveSize(6), // 6px
          },
          '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '100px',
            borderRadius: '100px',
            margin: `${getResponsiveSize(8)} auto`, // 8px auto
          },
          '&::-webkit-scrollbar-thumb': {
            webkitBorderRadius: '100px',
            borderRadius: '100px',
            background: theme.palette.myPrimary.lightBlue20,
          },
        },
        // Laptop screen
        [theme.breakpoints.media.laptop]: {
          margin: `${getResponsiveSize(8, laptopRatio)} auto`, // 8px auto
          '& .MuiList-root': {
            py: getResponsiveSize(8, laptopRatio), // 8px
            mb: getResponsiveSize(80, laptopRatio), // 80px
            '& .MuiMenuItem-root': {
              fontSize: getResponsiveSize(16, laptopRatio), // 16px
              mx: getResponsiveSize(12, laptopRatio), // 12px,
              minHeight: getResponsiveSize(32, laptopRatio), // 32px,
              padding: `${getResponsiveSize(
                6,
                laptopRatio,
              )} ${getResponsiveSize(9, laptopRatio)}`, // 6px 9px
            },
            '& .hints': {
              bottom: `calc(${getResponsiveSize(80, laptopRatio)} * -1)`, // -80px
            },
            '&::-webkit-scrollbar': {
              width: getResponsiveSize(6, laptopRatio), // 6px
            },
            '&::-webkit-scrollbar-track': {
              webkitBorderRadius: '100px',
              borderRadius: '100px',
              margin: `${getResponsiveSize(8, laptopRatio)} auto`, // 8px auto
            },
            '&::-webkit-scrollbar-thumb': {
              webkitBorderRadius: '100px',
              borderRadius: '100px',
              background: theme.palette.myPrimary.lightBlue20,
            },
          },
        },
        // Tablet screen
        [theme.breakpoints.media.tablet]: {
          '& .MuiList-root': {
            overflowY: 'auto',
            mb: 0,
          },
        },
        // Table simple responsive screen
        [theme.breakpoints.media.tableResponsive]: {
          '& .MuiList-root': {
            maxHeight: getResponsiveSize(140, mobileRatio), // 140px
          },
        },
      },
    },
  },
};
