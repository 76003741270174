import { memo, useCallback } from 'react';
import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AddchartRounded } from '@mui/icons-material';
import { ReactComponent as PlayIcon } from '../../../shared/assets/icons/play.svg';
import { useActions } from '../../../hooks/useActions';

import { ModalUI } from '../../UI/Modal/Modal';
import { ButtonUi } from '../../UI/Button/Button';
import translateClientName from '../../../utils/TranslatedPossibleClientName';
import { sessionsModalStyle } from './style';

export const CreateContinueSessionModal = memo((props) => {
  const { open, setOpen, treatmentUUID, sessionUUID, clientName } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { sessionsModal } = sessionsModalStyle;
  const { createSessionByTreatmentUUID, resumePreviousSession } = useActions();

  const title =
    t('treatments.configuration.modal_title') + translateClientName(clientName);

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const createNewSession = useCallback(async () => {
    const { sessionUUID } = await createSessionByTreatmentUUID(treatmentUUID);
    setOpen(false);
    history.push({
      pathname: '/admin/treatments/create',
      search: `?treatmentUUID=${treatmentUUID}&sessionUUID=${sessionUUID}`,
    });
  }, [createSessionByTreatmentUUID, history, setOpen, treatmentUUID]);

  const resumeLatestSession = useCallback(async () => {
    await resumePreviousSession(treatmentUUID, sessionUUID);
    setOpen(false);
    history.push({
      pathname: '/admin/treatments/create',
      search: `?treatmentUUID=${treatmentUUID}&sessionUUID=${sessionUUID}`,
    });
  }, [history, resumePreviousSession, sessionUUID, setOpen, treatmentUUID]);

  return (
    <ModalUI
      modalSx={sessionsModal}
      open={open}
      onCloseHandler={onClose}
      className='sessions-modal'
      modalTitle={title}
      isCloseBtn={false}
    >
      <Box className='actions'>
        <ButtonUi
          className='new-session'
          variant='outlined'
          size='small'
          onClickHandler={createNewSession}
          startIcon={<AddchartRounded />}
        >
          {t('treatments.configuration.new_session')}
        </ButtonUi>
        {sessionUUID && (
          <ButtonUi
            className='resume-session'
            variant='outlined'
            size='small'
            onClickHandler={resumeLatestSession}
            startIcon={<PlayIcon />}
          >
            {t('treatments.configuration.resume_session')}
          </ButtonUi>
        )}
      </Box>
    </ModalUI>
  );
});
