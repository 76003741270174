import { theme, laptopRatio } from '../../../../../../theme';
import { getResponsiveSize } from '../../../../../../utils';

export const advancedConfigurationStyle = {
  advancedConfig: {
    display: 'flex',
    minHeight: getResponsiveSize(535), // 535px
    maxHeight: getResponsiveSize(535), // 535px
    ...theme.palette.propVars.modalBg,
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      px: getResponsiveSize(36), // 36px
      pt: getResponsiveSize(32.5), // 32.5px
      minWidth: `calc(${getResponsiveSize(1010)} * 0.32)`, // 32% of 1010px
      width: `calc(${getResponsiveSize(1010)} * 0.32)`, // 32% of 1010px
      background: '#2392BA0D',
      height: '100%',
      gap: getResponsiveSize(10), // 10px
      '& .MuiButtonBase-root': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: 'unset',
        justifyContent: 'flex-start',
        textAlign: 'left',
        alignItems: 'center',
        textTransform: 'none',
        fontSize: getResponsiveSize(20), // 20px
        lineHeight: getResponsiveSize(18), // 18px
        color: theme.palette.myPrimary.lightBlue100,
        p: getResponsiveSize(12.5), // 12.5px
        minHeight: 'unset',
        height: getResponsiveSize(50), // 50px
        borderRadius: theme.palette.propVars.borderRadius,
        ...theme.palette.propVars.regularFontWeight,
        ...theme.palette.propVars.easeInOutTransition,
        '& svg': {
          m: 0,
          width: getResponsiveSize(24), // 24px
          height: getResponsiveSize(25), // 25px
          mr: getResponsiveSize(12), // 12px
          fill: theme.palette.myPrimary.lightBlue100,
          ...theme.palette.propVars.easeInOutTransition,
          '&.general': {
            '& path': {
              '&:nth-of-type(3), :nth-of-type(1)': {
                stroke: theme.palette.myPrimary.lightBlue100,
              },
            },
          },
        },
        '&:hover': {
          background: theme.palette.myPrimary.lightBlue50,
        },
        '&.Mui-selected': {
          background: theme.palette.myPrimary.lightBlue100,
          color: theme.palette.myPrimary.white,
          fontWeight: 700,
          '& svg': {
            fill: theme.palette.myPrimary.white,
            '&.general': {
              '& path': {
                '&:nth-of-type(3), :nth-of-type(1)': {
                  stroke: theme.palette.myPrimary.white,
                },
              },
            },
          },
        },
      },
    },
    '& .tab-wrapper': {
      px: getResponsiveSize(36), // 36px
      py: getResponsiveSize(31.5), // 31.5px
      overflowX: 'hidden',
      overflowY: 'auto',
      mr: getResponsiveSize(6), // 6px
      ...theme.palette.propVars.modalBg,
      '&::-webkit-scrollbar': {
        width: getResponsiveSize(6), // 6px
      },
      '&::-webkit-scrollbar-track': {
        webkitBorderRadius: '100px',
        borderRadius: '100px',
        margin: `${getResponsiveSize(8)} auto`, // 8px auto
      },
      '&::-webkit-scrollbar-thumb': {
        webkitBorderRadius: '100px',
        borderRadius: '100px',
        background: theme.palette.myPrimary.lightBlue20,
      },
      '& .MuiSlider-root': {
        width: getResponsiveSize(405), // 405px
        '& .MuiSlider-mark': {
          '&[data-index="0"]': {
            left: `calc(${getResponsiveSize(405)} * 0.012) !important`, // 12% from 405px
          },
          '&[data-index="4"]': {
            left: `calc(${getResponsiveSize(405)} * 0.995) !important`,
          },
        },
      },
      '& .slider-labels': {
        width: getResponsiveSize(405), // 405px
      },
      '& .reset-btn': {
        ml: 'auto',
        '& .reset': {
          p: getResponsiveSize(2),
          '& svg': {
            fontSize: getResponsiveSize(24),
            ...theme.palette.propVars.easeInOutTransition,
          },
        },
        '&:hover': {
          '& svg': {
            color: theme.palette.myPrimary.lightBlue100,
            transform: 'rotate(-45deg)',
          },
        },
      },
      '& .checkbox-wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      },
      '& .setting-wrapper': {
        '&.divider': {
          pb: getResponsiveSize(32), // 32px
          '&:not(:last-of-type)': {
            mb: getResponsiveSize(32), // 32px
            borderBottom: `1px solid ${theme.palette.myPrimary.lightBlue20}`,
          },
        },
        '&.slider.divider': {
          pb: getResponsiveSize(64), // 64px
        },
        '& .title-row': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          mb: getResponsiveSize(32), // 32px
          alignItems: 'center',
          '& .info': {
            display: 'flex',
            alignSelf: 'flex-start',
            cursor: 'help',
            fontSize: getResponsiveSize(18),
            ml: 1,
            borderRadius: '50%',
            p: getResponsiveSize(4),
            background: theme.palette.myPrimary.lightBlue70,
            color: theme.palette.myPrimary.white,
            ...theme.palette.propVars.linearTransition,
            '&:hover': {
              background: theme.palette.myPrimary.lightBlue100,
            },
          },
        },
        '& .title': {
          color: theme.palette.neutral.grey70,
          fontFamily: theme.typography.secondaryFontFamily,
          fontSize: getResponsiveSize(24), // 24px
          ...theme.palette.propVars.regularFontWeight,
        },
        '& .task_include_clicks': {
          '& .mask': {
            width: getResponsiveSize(153), // 153px
          },
        },
        '& .task_sphere_color': {
          width: getResponsiveSize(468), // 468px
          height: getResponsiveSize(60), // 60px
          '& .dropdown-wrapper, .MuiButtonBase-root.task_sphere_random_clr, .mask':
            {
              width: getResponsiveSize(222), // 222px
            },
          '& .mask-box .mask.right': {
            left: `calc(50% + ${getResponsiveSize(4.5)})`,
          },
        },
        '&.task_sphere_speed .slider-labels .mark-label:nth-of-type(2)': {
          left: '45%',
        },
      },
      '& .toggle:not(.task_include_clicks, .task_sphere_color )': {
        width: getResponsiveSize(470), // 470px
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        '&:not(:last-of-type)': {
          mb: getResponsiveSize(32), // 32px
        },
        '& .icon svg': {
          width: getResponsiveSize(83), // 83px
        },
        '&.task_sphere_reaction_include': {
          '& .icon svg': {
            height: getResponsiveSize(60), // 60px
          },
        },
        '&.task_sphere_audio': {
          '& .icon svg': {
            height: getResponsiveSize(37), // 37px
          },
        },
        '&.task_sphere_path_reverse': {
          '& .icon svg': {
            height: getResponsiveSize(40), // 40px
          },
        },
        '&.task_reaction_feedback ': {
          '& .icon svg': {
            height: getResponsiveSize(54), // 54px
          },
        },
        '&.task_clicks_random_noise  ': {
          '& .icon svg': {
            height: getResponsiveSize(39), // 39px
          },
        },
        '& .MuiButtonBase-root.MuiCardActionArea-root': {
          backgroundColor: theme.palette.myPrimary.lightGrey,
          border: 'none',
          '& .card-title': {
            fontSize: theme.typography.fs19,
            mb: `calc(${theme.palette.propVars.index} * 0.13)`,
          },
        },
      },
    },

    [theme.breakpoints.media.desktop]: {
      '& .tab-wrapper .setting-wrapper .title-row .info': {
        fontSize: '18px',
        p: '3px',
        ml: 0.5,
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      minHeight: getResponsiveSize(400, laptopRatio), // 400px
      maxHeight: getResponsiveSize(400, laptopRatio), // 400px
      '& .MuiTabs-flexContainer': {
        minWidth: `calc(${getResponsiveSize(680, laptopRatio)} * 0.32)`, // 32% of 680px
        width: `calc(${getResponsiveSize(680, laptopRatio)} * 0.32)`, // 32% of 680px
        gap: getResponsiveSize(10, laptopRatio), // 10px
        '& .MuiButtonBase-root': {
          fontSize: getResponsiveSize(15, laptopRatio), // 16px
          lineHeight: getResponsiveSize(18, laptopRatio), // 18px
          p: getResponsiveSize(9, laptopRatio), // 9px
          height: getResponsiveSize(42, laptopRatio), // 42px
          '& svg': {
            width: getResponsiveSize(19, laptopRatio), // 19px
            height: getResponsiveSize(20, laptopRatio), // 20px
            mr: getResponsiveSize(8, laptopRatio), // 8px
          },
        },
      },
      '& .tab-wrapper': {
        '& .MuiSlider-root': {
          width: getResponsiveSize(285, laptopRatio), // 285px
          '& .MuiSlider-mark': {
            '&[data-index="0"]': {
              left: `calc(${getResponsiveSize(
                285,
                laptopRatio,
              )} * 0.012) !important`, // 12% from 305px
            },
            '&[data-index="4"]': {
              left: `calc(${getResponsiveSize(
                285,
                laptopRatio,
              )} * 0.995) !important`,
            },
          },
        },
        '& .slider-labels': {
          width: getResponsiveSize(285, laptopRatio), // 285px
        },
        '& .reset-btn': {
          '& .reset': {
            p: getResponsiveSize(2, laptopRatio),
            '& svg': {
              fontSize: getResponsiveSize(20, laptopRatio),
            },
          },
        },
        '& .setting-wrapper': {
          '& .title-row': {
            mb: getResponsiveSize(32), // 32px
          },
          '& .title': {
            fontSize: getResponsiveSize(19, laptopRatio), // 19px
          },
          '& .task_include_clicks': {
            '& .mask': {
              width: getResponsiveSize(125, laptopRatio), // 125px
            },
          },
          '& .task_sphere_color': {
            width: getResponsiveSize(285, laptopRatio), // 285px
            height: 'auto',
            '& .mask': {
              width: getResponsiveSize(130, laptopRatio), // 130px
            },
            '& .dropdown-wrapper':
              // '& .dropdown-wrapper, .MuiButtonBase-root.task_sphere_random_clr, .mask':
              {
                pl: getResponsiveSize(5, laptopRatio), // 5px
                width: getResponsiveSize(120, laptopRatio), // 130px
                '& .dropdown-title ': {
                  display: 'none',
                },
                '& .MuiInputBase-root': {
                  width: getResponsiveSize(120, laptopRatio), // 120px
                  height: getResponsiveSize(35, laptopRatio), // 35px
                },
                '& .chevron': {
                  right: `calc(${getResponsiveSize(13, laptopRatio)} * 0.5)`,
                  width: getResponsiveSize(20, laptopRatio), // 20px
                  height: getResponsiveSize(20, laptopRatio), // 20px
                },
              },
            '& .mask-box .mask.right': {
              left: `calc(50% + ${getResponsiveSize(4.5)})`,
            },
          },
        },
        '& .toggle:not(.task_include_clicks, .task_sphere_color )': {
          width: getResponsiveSize(370, laptopRatio), // 370px
          minHeight: getResponsiveSize(65.5, laptopRatio), // 65.5px
          '& .MuiButtonBase-root.MuiCardActionArea-root .card-title': {
            fontSize: getResponsiveSize(15, laptopRatio), // 15px,
          },
        },
      },
    },
  },
};
