import { getI18n } from 'react-i18next';
import axios from 'axios';
import { treatmentsRemoteConstants } from '../constants/treatment-remote.constants';
import { alertActions } from './alert.actions';
import BASEURL from '../../utils/config';
import { authUrl } from '../../utils/auth-header';
import { history } from '../../utils/history';
import { treatmentService } from '../../services/treatments';

// TODO: CHECK ERROR MESSAGES

/**
 * Create a remote-treatment
 * @param data : {values:array,organizationId:string,clientId:string,therapistId:string,configurationdefinitionId:string}
 */
function createTreatmentRemote(data) {
  return (dispatch) => {
    dispatch(request(data));
    return axios
      .post(`${BASEURL}/remotetreatmentconfigurations${authUrl()}`, data)
      .then((result) => {
        const { generated_UUID, id: remoteTreatmentId, deviceId } = result.data;
        const sessionData = {
          ...data,
          treatmentUUID: generated_UUID,
          start_date: new Date(),
          remotetreatmentconfigurationId: remoteTreatmentId,
          deviceId,
        };
        return axios
          .post(`${BASEURL}/sessions${authUrl()}`, sessionData)
          .then((sessionResult) => {
            const { sessionUUID } = sessionResult.data;
            history.push({
              pathname: '/admin/treatments/create',
              search: `?treatmentUUID=${generated_UUID}&sessionUUID=${sessionUUID}`,
            });
            dispatch(
              success({ sessionUUID, treatmentUUID: generated_UUID, deviceId }),
            );
            return sessionResult.data;
          });
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(
          alertActions.error(getI18n().t('errors.add.treatment-config')),
        );
        return error;
      });
  };

  function request(config) {
    return { type: treatmentsRemoteConstants.CREATE_CONFIG_REQUEST, config };
  }

  function success(config) {
    return { type: treatmentsRemoteConstants.CREATE_CONFIG_SUCCESS, config };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.CREATE_CONFIG_FAILURE, error };
  }
}

function blockTreatmentRemote(treatmentUUID) {
  const data = { generated_UUID: treatmentUUID };
  const url = `${BASEURL}/remotetreatmentconfigurations/block${authUrl()}`;
  return (dispatch) => {
    dispatch(request(treatmentUUID));
    return axios
      .patch(url, data)
      .then((result) => {
        dispatch(success(result.data));
        return result.data;
      })
      .catch((error) => {
        dispatch(failure('TODO'));
      });
  };

  function request(config) {
    return { type: treatmentsRemoteConstants.PATCH_CONFIG_REQUEST, config };
  }

  function success(config) {
    return { type: treatmentsRemoteConstants.PATCH_CONFIG_SUCCESS, config };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.PATCH_CONFIG_FAILURE, error };
  }
}

function checkIfConfigurationIsReceived(id) {
  return (dispatch) => {
    dispatch(request(id));
    return axios
      .get(
        `${BASEURL}/remotetreatmentconfigurations/getByGeneratedUUID${authUrl()}&generated_UUID=${id}`,
      )
      .then(
        (result) => {
          dispatch(success(result.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(id) {
    return { type: treatmentsRemoteConstants.GET_CONFIG_REQUEST, id };
  }

  function success(data) {
    return { type: treatmentsRemoteConstants.GET_CONFIG_SUCCESS, data };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.GET_CONFIG_FAILURE, error };
  }
}

function getAllRemoteTreatments(organizationId) {
  let filterString = '';
  if (organizationId) {
    filterString = `&organizationId=${organizationId}`;
  }
  return (dispatch) => {
    dispatch(request());
    return axios
      .get(
        `${BASEURL}/remotetreatmentconfigurations/overview${authUrl()}${filterString}`,
      )
      .then(
        (result) => {
          const { data } = result;
          const remoteTreatments = data.treatments;
          if (remoteTreatments && remoteTreatments.length > 0) {
            let attributes = Object.keys(remoteTreatments[0]);
            attributes = [...new Set(attributes)];
            dispatch(success({ attributes, data: remoteTreatments }));
          } else {
            dispatch(success({ attributes: [], data: remoteTreatments }));
          }
          return result.data;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(getI18n().t('errors.get.treatments')));
          return error;
        },
      );
  };

  function request() {
    return { type: treatmentsRemoteConstants.GET_ALL_CONFIG_REQUEST };
  }

  function success(remoteTreatments) {
    return {
      type: treatmentsRemoteConstants.GET_ALL_CONFIG_SUCCESS,
      remoteTreatments,
    };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.GET_ALL_CONFIG_FAILURE, error };
  }
}

function getTreatmentRemoteConfigurationById(treatmentId) {
  return (dispatch) =>
    treatmentService.getTreatmentRemoteConfigurationById(treatmentId).then(
      (result) => result.data,
      (error) =>
        dispatch(alertActions.error(getI18n().t('errors.get.treatments'))),
    );
}

function createSessionByTreatmentUUID(treatmentUUID) {
  const data = { treatmentUUID, start_date: new Date() };
  return (dispatch) => {
    dispatch(request(data));
    return axios.post(`${BASEURL}/sessions${authUrl()}`, data).then(
      (result) => {
        dispatch(success(result.data));
        return result.data;
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(
          alertActions.error(getI18n().t('errors.add.treatment-config')),
        );
        return error;
      },
    );
  };

  function request(treatmentUUID) {
    return {
      type: treatmentsRemoteConstants.CREATE_SESSION_REQUEST,
      treatmentUUID,
    };
  }

  function success(session) {
    return { type: treatmentsRemoteConstants.CREATE_SESSION_SUCCESS, session };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.CREATE_SESSION_FAILURE, error };
  }
}

function resumePreviousSession(remoteTreatmentUUID, remoteSessionUUID) {
  return (dispatch) => {
    dispatch(request({ remoteTreatmentUUID, remoteSessionUUID }));
  };

  function request(data) {
    return { type: treatmentsRemoteConstants.RESUME_SESSION, data };
  }
}

function clearTreatmentRemoteState() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: treatmentsRemoteConstants.CLEAR_STATE };
  }
}

function deleteRemoteTreatmentById(id) {
  return (dispatch) => {
    dispatch(request(id));
    return axios
      .delete(`${BASEURL}/remotetreatmentconfigurations/${id}${authUrl()}`)
      .then(
        (result) => {
          dispatch(success(id));
          return id;
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(
            alertActions.error(getI18n().t('errors.add.treatment-config')),
          );
          return error;
        },
      );
  };

  function request(treatmentUUID) {
    return { type: treatmentsRemoteConstants.DELETE_TREATMENT, treatmentUUID };
  }

  function success(id) {
    return { type: treatmentsRemoteConstants.DELETE_TREATMENT_SUCCESS, id };
  }

  function failure(error) {
    return { type: treatmentsRemoteConstants.DELETE_TREATMENT_FAILURE, error };
  }
}

export const treatmentRemoteActions = {
  createTreatmentRemote,
  blockTreatmentRemote,
  checkIfConfigurationIsReceived,
  getAllRemoteTreatments,
  createSessionByTreatmentUUID,
  resumePreviousSession,
  clearTreatmentRemoteState,
  deleteRemoteTreatmentById,
  getTreatmentRemoteConfigurationById,
};
