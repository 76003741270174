import { Box, Grow, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { QuestionMarkRounded, RestartAltRounded } from '@mui/icons-material';

import { classNames } from '../../../../../../../utils';
import { mapIconsNameToIcon } from '../../../../../../../utils/wizard-helper';
import CheckboxCard from '../../../../../../UI/CheckboxCard/CheckboxCard';
import CombineToggle from '../../../../../../UI/CombineToggle/CombineToggle';
import RangeInput from '../../../../../../UI/RangeInput/RangeInput';
import SelectUI from '../../../../../../UI/Select/Select';
import SelectionCardGroup from '../../../../../../UI/SelectionCardGroup/SelectionCardGroup';
import { ButtonUi } from '../../../../../../UI/Button/Button';

function ConfigurationTab(props) {
  const {
    baseConfigValues,
    group,
    getVariableTranslation,
    getConfigVariableValueByName,
    handleVariableChange,
    updateSelectedConfig,
    t,
  } = props;
  const { variables } = group;
  const singleSetting = variables.length <= 1;
  const [changedVals, setChangedVals] = useState({});

  /**
   * Mutate property in object with it's translation
   * @param obj to mutate property in
   * @param property to mutate
   * @returns mutated object
   */
  const getTranslatedObjByProperty = useCallback(
    (obj, property) =>
      obj?.map((item) => ({
        ...item,
        [property]: t(`configurations.EMDR-VR.values.${item[property]}`),
      })),
    [t],
  );

  /**
   * Handle checkbox toggle change and set variable value
   * @param value variable toggle value
   */
  const onCheckboxChange = useCallback(
    (name, value) => {
      handleVariableChange({
        variable: name,
        value,
      });
      setChangedVals((prev) => ({ ...prev, [name]: value }));
    },
    [handleVariableChange],
  );

  /**
   * Handle variable value changes
   * @param value variable value {variable, value}
   */
  const onVariableChange = useCallback(
    (value) => {
      handleVariableChange({
        variable: value.variable,
        value: value.value,
      });
      setChangedVals((prev) => ({ ...prev, [value.variable]: value.value }));
    },
    [handleVariableChange],
  );

  /**
   * Reset variable value to default
   * @param resetVars object contains {variableName: defaultValue} structure
   */
  const onResetValue = useCallback(
    (resetVars) => {
      Object.keys(resetVars).forEach((variable) => {
        handleVariableChange({
          variable,
          value: resetVars[variable],
        });
        setChangedVals((prev) => ({
          ...prev,
          [variable]: resetVars[variable],
        }));
      });
    },
    [handleVariableChange],
  );

  /**
   * Generates configuration slider component for variable
   * @param variable configuration variable
   * @returns configuration slider component for variable
   */
  const getSlider = (variable) => {
    const min = variable?.ui_configuration?.slider_min;
    const max = variable?.ui_configuration?.slider_max;
    const step = variable?.ui_configuration?.slider_step;
    const isPercentage = variable?.ui_configuration?.percentage;
    const label = variable?.ui_configuration?.slider_label
      ? variable?.ui_configuration?.slider_label === '%'
        ? '%'
        : t(
            `create-treatment-wizard.advanced-configurations-modal.slider_label.${variable?.ui_configuration?.slider_label}`,
          )
      : t(
          'create-treatment-wizard.advanced-configurations-modal.slider_label.min',
        );

    const sliderRange = max - min;
    const sliderValue =
      changedVals?.[variable.name] != undefined
        ? changedVals?.[variable.name]
        : getConfigVariableValueByName(variable.name);

    const baseConfigVarValue = baseConfigValues.find(
      (configVar) => configVar.name === variable.name,
    ).value;

    const isChanged =
      changedVals?.[variable.name] === undefined
        ? baseConfigVarValue !== getConfigVariableValueByName(variable.name)
        : !!(baseConfigVarValue != changedVals?.[variable.name]);

    // Slider marks
    const marks = [
      // min
      {
        value: min,
        label: `${min} ${label}`,
      },
      // 1/4
      {
        value: sliderRange / 4 + min,
      },
      // 1/2
      {
        value:
          sliderRange > 30
            ? Math.round(Math.round(sliderRange / 2 + min) / 10) * 10
            : Math.round(Math.round(sliderRange / 2 + min) / 5) * 5,
        label: `${
          sliderRange > 30
            ? Math.round(Math.round(sliderRange / 2 + min) / 10) * 10
            : Math.round(Math.round(sliderRange / 2 + min) / 5) * 5
        } ${label}`,
      },
      // 3/4
      {
        value: sliderRange * 0.75 + min,
      },
      // max
      {
        value: max,
        label: `${max} ${label}`,
      },
    ];

    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting }, [
          'slider',
          variable?.name,
        ])}
      >
        <Box className='title-row'>
          <Typography variant='h3' className='title'>
            {getVariableTranslation(variable?.name)}
          </Typography>
          <Tooltip
            title={t(
              `configurations.EMDR-VR.variable-description.${variable?.name}`,
            )}
            placement='right'
            className='info'
          >
            <QuestionMarkRounded />
          </Tooltip>

          <Grow in={isChanged}>
            <Box className='reset-btn'>
              <ButtonUi
                isIconBtn
                tooltipTitle={t(
                  'buttons.treatment-wizard.advanced-configurations-reset',
                )}
                tooltipPlacement='bottom'
                size='small'
                className='reset'
                onClickHandler={() =>
                  onResetValue({ [variable.name]: baseConfigVarValue })
                }
              >
                <RestartAltRounded />
              </ButtonUi>
            </Box>
          </Grow>
        </Box>

        <RangeInput
          rangeValue={+sliderValue}
          inputValue={+sliderValue}
          min={min}
          max={max}
          step={step ? step : 1}
          name={variable?.name}
          isPercentage={isPercentage}
          marks={marks}
          onSliderChangeCommittedHandler={onVariableChange}
        />
      </Box>
    );
  };

  /**
   * Generates configuration dropdown component for variable
   * @param variable configuration variable
   * @returns configuration dropdown component for variable
   */
  const getDropdown = (variable) => {
    const dropdownOptions = variable?.ui_configuration.values;
    const selectValue =
      changedVals?.[variable.name] ||
      getConfigVariableValueByName(variable.name);

    const baseConfigVarValue = baseConfigValues.find(
      (configVar) => configVar.name === variable.name,
    ).value;

    const isChanged =
      changedVals?.[variable.name] === undefined
        ? baseConfigVarValue !== getConfigVariableValueByName(variable.name)
        : !!(baseConfigVarValue != changedVals?.[variable.name]);

    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting })}
      >
        <Box className='title-row'>
          <Typography variant='h3' className='title'>
            {getVariableTranslation(variable?.name)}
          </Typography>
          <Tooltip
            title={t(
              `configurations.EMDR-VR.variable-description.${variable?.name}`,
            )}
            placement='right'
            className='info'
          >
            <QuestionMarkRounded />
          </Tooltip>
          <Grow in={isChanged}>
            <Box className='reset-btn'>
              <ButtonUi
                isIconBtn
                tooltipTitle={t(
                  'buttons.treatment-wizard.advanced-configurations-reset',
                )}
                tooltipPlacement='bottom'
                size='small'
                className='reset'
                onClickHandler={() =>
                  onResetValue({ [variable.name]: baseConfigVarValue })
                }
              >
                <RestartAltRounded />
              </ButtonUi>
            </Box>
          </Grow>
        </Box>

        <SelectUI
          idPrefix={variable?.name}
          size='medium'
          name={variable?.name}
          selectValue={selectValue}
          renderValue={(value) =>
            dropdownOptions.find((option) => option?.value == value)?.label ||
            value
          }
          onChangeHandler={onVariableChange}
          options={dropdownOptions.map((option) => ({
            text: option?.label || option,
            value: option.value || option,
          }))}
        />
      </Box>
    );
  };

  /**
   * Generates configuration combine toggle component for variable
   * @param variable configuration variable
   * @returns configuration combine toggle component for variable
   */
  const getCombineToggle = (variable) => {
    const secondaryConfigVariable =
      variable.name === 'task_include_clicks'
        ? 'task_clicks_replace'
        : 'task_sphere_random_clr';

    const selectedOption = changedVals?.[variable.name]
      ? changedVals?.[variable.name]
      : getConfigVariableValueByName(variable.name);

    const baseConfigVarValue = baseConfigValues.find(
      (configVar) => configVar.name === variable.name,
    ).value;
    const secondaryConfigVariableBaseConfigValue = baseConfigValues.find(
      (configVar) => configVar.name === secondaryConfigVariable,
    )?.value;

    const isChanged =
      secondaryConfigVariable === 'task_sphere_random_clr'
        ? changedVals?.[variable.name] === undefined
          ? baseConfigVarValue !==
              getConfigVariableValueByName(variable.name) ||
            secondaryConfigVariableBaseConfigValue !==
              getConfigVariableValueByName(secondaryConfigVariable)
          : typeof changedVals?.[secondaryConfigVariable] === 'string'
          ? !!(
              secondaryConfigVariableBaseConfigValue !=
              changedVals?.[secondaryConfigVariable]
            )
          : !!(
              secondaryConfigVariableBaseConfigValue !=
              JSON.stringify(changedVals?.[secondaryConfigVariable])
            ) || !!(baseConfigVarValue != changedVals?.[variable.name])
        : changedVals?.[variable.name] === undefined
        ? baseConfigVarValue !== getConfigVariableValueByName(variable.name)
        : typeof changedVals?.[variable.name] === 'string'
        ? !!(baseConfigVarValue != changedVals?.[variable.name])
        : !!(
            baseConfigVarValue != JSON.stringify(changedVals?.[variable.name])
          );

    const selectValue =
      secondaryConfigVariable === 'task_sphere_random_clr'
        ? changedVals?.[secondaryConfigVariable] !== undefined
          ? typeof changedVals?.[secondaryConfigVariable] === 'string'
            ? changedVals?.[secondaryConfigVariable] == 'false'
            : !changedVals?.[secondaryConfigVariable]
          : typeof getConfigVariableValueByName(secondaryConfigVariable) ===
            'string'
          ? getConfigVariableValueByName(secondaryConfigVariable) == 'false'
          : !getConfigVariableValueByName(secondaryConfigVariable)
        : changedVals?.[variable.name] !== undefined
        ? typeof changedVals?.[variable.name] === 'string'
          ? changedVals?.[variable.name] == 'true'
          : changedVals?.[variable.name]
        : typeof getConfigVariableValueByName(variable.name) === 'string'
        ? getConfigVariableValueByName(variable.name) == 'true'
        : getConfigVariableValueByName(variable.name);

    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting })}
      >
        <Box className='title-row'>
          <Typography variant='h3' className='title'>
            {getVariableTranslation(variable?.name)}
          </Typography>
          <Tooltip
            title={t(
              `configurations.EMDR-VR.variable-description.${variable?.name}`,
            )}
            placement='right'
            className='info'
          >
            <QuestionMarkRounded />
          </Tooltip>
          <Grow in={isChanged}>
            <Box className='reset-btn'>
              <ButtonUi
                isIconBtn
                tooltipTitle={t(
                  'buttons.treatment-wizard.advanced-configurations-reset',
                )}
                tooltipPlacement='bottom'
                size='small'
                className='reset'
                onClickHandler={() =>
                  onResetValue({
                    [variable.name]: baseConfigVarValue,
                    [secondaryConfigVariable]:
                      secondaryConfigVariableBaseConfigValue,
                  })
                }
              >
                <RestartAltRounded />
              </ButtonUi>
            </Box>
          </Grow>
        </Box>
        <CombineToggle
          name={variable?.name}
          className={variable?.name}
          selectValue={selectValue}
          selectedOption={selectedOption}
          options={getTranslatedObjByProperty(
            variable.ui_configuration.toggle_options,
            'content',
          )}
          onChangeHandler={onVariableChange}
          getVariableTranslation={getVariableTranslation}
          optionIcon={variable?.name === 'task_sphere_color'}
        />
      </Box>
    );
  };

  /**
   * Generates configuration toggle checkebox component for variable
   * @param variable configuration variable
   * @returns configuration toggle checkebox component for variable
   */
  const getToggle = (variable) => {
    const checkboxItem = {
      title: getVariableTranslation(variable?.name),
      desc: `configurations.EMDR-VR.values.${variable?.ui_configuration?.desc}`,
      checked:
        changedVals?.[variable.name] !== undefined
          ? typeof changedVals?.[variable.name] === 'string'
            ? changedVals?.[variable.name] === 'true'
            : changedVals?.[variable.name]
          : typeof getConfigVariableValueByName(variable.name) === 'string'
          ? getConfigVariableValueByName(variable.name) === 'true'
          : getConfigVariableValueByName(variable.name),
      Icon: mapIconsNameToIcon(variable?.ui_configuration?.iconName),
    };
    const baseConfigVarValue = baseConfigValues.find(
      (configVar) => configVar.name === variable.name,
    ).value;

    const isChanged =
      changedVals?.[variable.name] === undefined
        ? baseConfigVarValue !== getConfigVariableValueByName(variable.name)
        : typeof changedVals?.[variable.name] === 'string'
        ? baseConfigVarValue != changedVals?.[variable.name]
        : baseConfigVarValue != JSON.stringify(changedVals?.[variable.name]);

    return (
      <Box className='checkbox-wrapper' key={variable?.name}>
        <CheckboxCard
          className={classNames('toggle', {}, [variable?.name])}
          checkboxItem={checkboxItem}
          onCardSelectHandler={(value) =>
            onCheckboxChange(variable?.name, value)
          }
          handleFromCheckbox
        />
        <Grow in={isChanged}>
          <Box className='reset-btn'>
            <ButtonUi
              isIconBtn
              tooltipTitle={t(
                'buttons.treatment-wizard.advanced-configurations-reset',
              )}
              tooltipPlacement='bottom'
              size='small'
              className='reset'
              onClickHandler={() =>
                onResetValue({ [variable.name]: baseConfigVarValue })
              }
            >
              <RestartAltRounded />
            </ButtonUi>
          </Box>
        </Grow>
      </Box>
    );
  };

  /**
   * Generates configuration selection card group component for variable
   * @param variable configuration variable
   * @returns configuration selection card group component for variable
   */
  const getSelectionCardGroup = (variable) => {
    const baseConfigVarValue = baseConfigValues.find(
      (configVar) => configVar.name === variable.name,
    ).value;

    const isChanged =
      changedVals?.[variable.name] === undefined
        ? baseConfigVarValue !== getConfigVariableValueByName(variable.name)
        : baseConfigVarValue != changedVals?.[variable.name];

    const selectValue =
      changedVals?.[variable.name] ||
      getConfigVariableValueByName(variable.name);

    return (
      <Box
        key={variable?.name}
        className={classNames('setting-wrapper', { divider: !singleSetting })}
      >
        <Box className='title-row'>
          <Typography variant='h3' className='title'>
            {getVariableTranslation(variable?.name)}
          </Typography>
          <Tooltip
            title={t(
              `configurations.EMDR-VR.variable-description.${variable?.name}`,
            )}
            placement='right'
            className='info'
          >
            <QuestionMarkRounded />
          </Tooltip>
          <Grow in={isChanged}>
            <Box className='reset-btn'>
              <ButtonUi
                isIconBtn
                tooltipTitle={t(
                  'buttons.treatment-wizard.advanced-configurations-reset',
                )}
                tooltipPlacement='bottom'
                size='small'
                className='reset'
                onClickHandler={() =>
                  onResetValue({ [variable.name]: baseConfigVarValue })
                }
              >
                <RestartAltRounded />
              </ButtonUi>
            </Box>
          </Grow>
        </Box>

        <SelectionCardGroup
          name={variable?.name}
          className={variable?.name}
          options={getTranslatedObjByProperty(
            variable.ui_configuration?.options,
            'content',
          )}
          selectValue={selectValue}
          onChangeHandler={onVariableChange}
        />
      </Box>
    );
  };

  /**
   * Generates configuration component based on variable
   * @param variable configuration variable
   * @returns configuration component
   */
  const getVariableLayout = (variable) => {
    const { type } = variable.ui_configuration;
    switch (type) {
      case 'slider':
        return getSlider(variable);
      case 'dropdown':
        return getDropdown(variable);
      case 'combine-toggle':
        return getCombineToggle(variable);
      case 'toggle':
        return getToggle(variable);
      case 'selection-card-group':
        return getSelectionCardGroup(variable);
    }
  };

  /**
   * Generates configuration group layout based on group's variables
   * @returns group layout
   */
  const groupLayout = useMemo(
    () =>
      variables
        .sort((a, b) => a?.ui_configuration?.order - b?.ui_configuration?.order)
        .map((variable) => getVariableLayout(variable)),
    [variables, changedVals],
  );

  useEffect(
    () => () => {
      updateSelectedConfig();
    },
    [],
  );

  return <>{groupLayout}</>;
}

export default withTranslation()(ConfigurationTab);
