import { Box, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { CheckRounded } from '@mui/icons-material';
import { useActions } from '../../../../../hooks/useActions';
import { useGenerateConfigCheckboxData } from '../../hooks/useGenerateConfigCheckboxData';

import { ReactComponent as ConfigurationsIcon } from '../../../../../shared/assets/icons/configurations.svg';

import { ButtonUi } from '../../../../UI/Button/Button';
import CheckboxCard from '../../../../UI/CheckboxCard/CheckboxCard';
import { ModalUI } from '../../../../UI/Modal/Modal';
import { WizardContentBox } from '../../../../UI/WizardContentBox/WizardContentBox';
import { useProductConfigTemplates } from '../../hooks/useProductConfigTemplates';
import { devicesStepStyles } from '../style';
import AdvancedConfiguration from './AdvancedConfiguration/AdvancedConfiguration';

function ProductConfiguration(props) {
  const { isActive, t, setContentBoxActive } = props;

  const { selectedDeviceConfig, productConfigTemplates: configurations } =
    useSelector((state) => state.createTreatmentWizard);

  const { advancedConfigModal } = devicesStepStyles;
  const { setDeviceConfig } = useActions();
  const [modalOpen, setModalOpen] = useState(false);

  /**
   * Generate configuration checkbox data
   */
  const configsCheckboxData = useGenerateConfigCheckboxData();

  /**
   * Handle taxation changed
   * @param taxation selected checkbox data
   */
  const handleTaxationSelect = useCallback(
    (taxation) => {
      setContentBoxActive(() => ({ selectDevice: false, selectCongif: true }));
      configsCheckboxData.map((item) =>
        item.id === taxation.id
          ? (item.checked = true)
          : (item.checked = false),
      );
      setDeviceConfig({
        ...configurations.find((config) => config.id === taxation.id),
      });
    },
    [configsCheckboxData, configurations, setContentBoxActive, setDeviceConfig],
  );

  /**
   * Open advanced configurations modal
   */
  const onAdvencedConfigurations = useCallback(
    () => setModalOpen(true),
    [setModalOpen],
  );

  /**
   * Close advanced configurations modal
   */
  const onCloseModal = useCallback(() => setModalOpen(false), [setModalOpen]);

  /**
   * Fetch configuration templates for product
   */
  useProductConfigTemplates();

  return (
    <>
      <WizardContentBox active={isActive} className='taxation-content-box'>
        <Box className='box-heading'>
          <Typography variant='h3' className='wizard-box-title'>
            {t(
              'create-treatment-wizard.select-device.configurations-select.title',
            )}
          </Typography>
          <ButtonUi
            startIcon={<ConfigurationsIcon />}
            className='config-btn'
            variant='outlined'
            type='secondary'
            size='small'
            disabled={!selectedDeviceConfig}
            onClickHandler={onAdvencedConfigurations}
            endIcon={selectedDeviceConfig?.isChanged ? <CheckRounded /> : null}
          >
            {t('buttons.treatment-wizard.advanced-configurations')}
          </ButtonUi>
        </Box>
        <Box className='checkbox-card-wrapper'>
          {configsCheckboxData.length > 0 &&
            configsCheckboxData.map((config) => (
              <CheckboxCard
                className='taxation-checkbox'
                key={config?.id}
                checkboxItem={config}
                onCardSelectHandler={handleTaxationSelect}
              />
            ))}
        </Box>
      </WizardContentBox>
      <ModalUI
        modalSx={advancedConfigModal}
        open={modalOpen}
        onCloseHandler={onCloseModal}
        className='advenced-config-modal'
        modalTitle={t(
          'create-treatment-wizard.advanced-configurations-modal.title',
        )}
      >
        <>
          <AdvancedConfiguration />
        </>
      </ModalUI>
    </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(memo(ProductConfiguration)),
);
