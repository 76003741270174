import { forwardRef } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { classNames } from '../../../utils';
import { buttonStyles } from './style';

export const ButtonUi = forwardRef((props, ref) => {
  const {
    children,
    variant,
    disabled,
    size,
    color,
    endIcon,
    startIcon,
    className,
    href,
    target,
    type = 'primary',
    onClickHandler,
    tooltipTitle,
    tooltipPlacement = 'bottom',
    isIconBtn = false,
    ariaLabel,
    btnType = 'button',
  } = props;
  const { button } = buttonStyles;

  const mods = {
    primaryBtn: type === 'primary',
    secondaryBtn: type === 'secondary',
    inputBtn: type === 'input',
  };

  return (
    <>
      {isIconBtn ? (
        <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
          <Box ref={ref} component='span' className='btn-wrapper'>
            <IconButton
              className={classNames(className, {})}
              disabled={disabled}
              size={size}
              color={color}
              href={href}
              target={target}
              aria-label={ariaLabel}
              onClick={onClickHandler}
              type={btnType}
            >
              {children}
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
          <Box component='span' className='btn-wrapper'>
            <Button
              sx={button}
              className={classNames(className, mods)}
              variant={variant}
              disabled={disabled}
              size={size}
              color={color}
              href={href}
              target={target}
              endIcon={endIcon}
              startIcon={startIcon}
              aria-label={ariaLabel}
              onClick={onClickHandler}
              type={btnType}
            >
              {children}
            </Button>
          </Box>
        </Tooltip>
      )}
    </>
  );
});
