import { createTreatmentWizardConstants } from '../constants/create-treatment-wizard.constants';
import { productsService } from '../../services/products';

export const setProduct = (product) => ({
    type: createTreatmentWizardConstants.SET_SELECTED_PRODUCT,
    payload: product,
  });

export const setClient = (user) => ({
    type: createTreatmentWizardConstants.SET_SELECTED_CLIENT,
    payload: user,
  });

export const setNotification = (isShown) => ({
    type: createTreatmentWizardConstants.SET_SHOWNOTIFICATION,
    payload: isShown,
  });

export const setAnonymousClient = (user) => ({
    type: createTreatmentWizardConstants.SET_ANONYMOUS_CLIENT,
    payload: user,
  });

export const setDevice = (device) => ({
    type: createTreatmentWizardConstants.SET_SELECTED_DEVICE,
    payload: device,
  });

export const setError = (error) => ({ type: createTreatmentWizardConstants.SET_ERROR, payload: error });

export const setAnonymousSession = (isAnonymous) => ({
    type: createTreatmentWizardConstants.SET_ANONYMOUSSESSION,
    payload: isAnonymous,
  });

export const setDeviceConfig = (config) => ({
    type: createTreatmentWizardConstants.SET_SELECTED_DEVICE_CONFIGURATION,
    payload: { ...config },
  });

export const setNextButton = (isActive) => ({
    type: createTreatmentWizardConstants.SET_NEXT_BUTTON,
    payload: isActive,
  });

export const setVrTimeout = (isTimeout) => ({
    type: createTreatmentWizardConstants.SET_TIMEOUT,
    payload: isTimeout,
  });

export const setPreviousSession = (isPrevious) => ({
    type: createTreatmentWizardConstants.SET_ISPREVIOUSSESSION,
    payload: isPrevious,
  });

export const setLocation = (location) => ({
    type: createTreatmentWizardConstants.SET_LOCATION,
    payload: location,
  });

export const addStep = (step) => ({ type: createTreatmentWizardConstants.ADD_STEP, payload: step });

export const removeStep = (step) => ({ type: createTreatmentWizardConstants.REMOVE_STEP, payload: step });

export const setDefaultSteps = () => ({ type: createTreatmentWizardConstants.SET_DEFAULT_STEPS });

export const getProductConfigTemplates = (id) => {
  return async (dispatch) => {
    dispatch(request());
    try {
      const response =
        await productsService.getConfigurationTemplatesOfProductId(id);
      const templates = response.data;
      dispatch(success(templates));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: createTreatmentWizardConstants.SEND_REQUEST };
  }

  function success(templates) {
    return {
      type: createTreatmentWizardConstants.GET_PRODUCT_CONFIG_TEMPLATES_SUCCESS,
      templates,
    };
  }

  function failure(error) {
    return {
      type: createTreatmentWizardConstants.GET_PRODUCT_CONFIG_TEMPLATES_FAILURE,
      error,
    };
  }
};

export const setActiveStep = (currentStep, steps, isForward = true) => {
  const nextStep = isForward
    ? steps.find(({ stepId }) => stepId === currentStep?.stepId + 1)
    : steps.find(({ stepId }) => stepId === currentStep?.stepId - 1);

  return {
    type: createTreatmentWizardConstants.SET_ACTIVE_STEP,
    payload: nextStep,
  };
};

export const resetWizardState = () => ({ type: createTreatmentWizardConstants.RESET_STATE });
