import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from './useActions';

export const useAccessTokenStorage = () => {
  const { logout } = useActions();
  const { t } = useTranslation();

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === 'token' && e.oldValue && !e.newValue) {
        // Your logout logic here
        logout();
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, [logout, t]);
};
