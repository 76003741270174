import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import moment from 'moment';

import { deviceActions } from '../../store/actions/device.actions';
import { productActions } from '../../store/actions/product.actions';
import { treatmentActions } from '../../store/actions/treatment.actions';
import { organizationActions } from '../../store/actions/organization.actions';
import { treatmentRemoteActions } from '../../store/actions/treatment-remote.actions';

import { ReactComponent as PlayIcon } from '../../shared/assets/icons/play.svg';
import { ReactComponent as DeleteIcon } from '../../shared/assets/icons/delete.svg';
import { ReactComponent as AddTreatmentIcon } from '../../shared/assets/icons/sidenav/treatment.svg';

import useDebounce from '../../hooks/useDebounce';
import { useTableFilterConfig } from '../../hooks/useTableFilterConfig';

import { sessionService } from '../../services/sessions';

import { classNames, getTransformedLogicTableFilterValue } from '../../utils';
// eslint-disable-next-line import/no-cycle
import { checkRouteAccess } from '../../utils/check-access';
import getMuiTheme from '../../utils/MUI-Style';
import tableConfig from '../../utils/MUI-table-config';
import muiComponents from '../../utils/MUI-table-components';
import translateClientName from '../../utils/TranslatedPossibleClientName';
import { muiTableLoadingSkeleton } from '../../utils/MUI-table-loading-skaleton';

import CustomDateSortHeader from '../Sessions/CustomDateSortHeader';
import { CreateContinueSessionModal } from './CreateContinueSessionModal/CreateContinueSessionModal';
import { ModalUI } from '../UI/Modal/Modal';
import TableHeader from '../UI/TableHeader/TableHeader';
import { DataCell } from '../UI/MuiDatatable/DataCell/DataCell';
import { deleteConfirmationStyle } from '../UI/SimpleModalContent/style';
import { ClientAvatar } from '../UI/MuiDatatable/ClientAvatar/ClientAvatar';
import SimpleModalContent from '../UI/SimpleModalContent/SimpleModalContent';
import CustomDateTimeLayout from '../UI/MuiDatatable/CustomDateTimeLayout/CustomDateTimeLayout';
import { CustomMuiDataTableFilter } from '../UI/MuiDatatable/CustomMuiDataTableFilter/CustomMuiDataTableFilter';
import { CustomMuiDatatableEmdrType } from '../UI/MuiDatatable/CustomMuiDatatableEmdrType/CustomMuiDatatableEmdrType';
import { CustomMuiDataTableBodyButton } from '../UI/MuiDatatable/CustomMuiDataTableBodyButton/CustomMuiDataTableBodyButton';

const TreatmentsTable = ({
  history,
  // reducers
  authentication,
  organizations,
  treatments,
  products: productsReducer,
  devices,
  // Actions
  getTreatmentsOverview,
  getAllOrganizations,
  getAllProductConfigurations,
  getAllDevicesNames,
  deleteTreatmentById,
  deleteRemoteTreatmentById,
  clearTreatments,
}) => {
  const { t } = useTranslation();
  const { deleteConfirmation } = deleteConfirmationStyle;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentSort, setCurrentSort] = useState('default');
  const [currentSortType, setCurrentSortType] = useState('');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  const [filterOpen, setFilterOpen] = useState(false);
  const [treatmentFilters, setTreatmentFilters] = useState();
  const tableRef = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [treatmentToDelete, setTreatmentToDelete] = useState(null);

  const [clientName, setClientName] = useState('');
  const [treatmentUUID, setTreatmentUUID] = useState('');
  const [sessionUUID, setSessionUUID] = useState('');

  const {
    isLoading: treatmentsIsLoading,
    data: treatmentsData,
    totalTreatments,
  } = treatments || {};
  const { userIsAdmin, practitioner, organizationId } = authentication;
  const { shortOrganizations: organizationOptions } = organizations;
  const { devicesNames } = devices;
  const { products } = productsReducer;

  const hasOrganizationAccess = userIsAdmin;

  const isTableLoading = treatmentsIsLoading;

  useEffect(() => {
    const { foundUser, roles, userIsAdmin } = authentication;
    checkRouteAccess(location.pathname, foundUser, roles, userIsAdmin, history);
  }, []);

  /**
   * Opens the filter's modal and grabs data for the filters
   */
  const onOpenFilterModal = useCallback(() => {
    if (devicesNames.length <= 0) getAllDevicesNames();
    if (organizationOptions.length <= 0 && hasOrganizationAccess)
      getAllOrganizations();
    if (products.length <= 0) getAllProductConfigurations();
    setFilterOpen(true);
  }, [
    devicesNames,
    getAllDevicesNames,
    getAllOrganizations,
    getAllProductConfigurations,
    hasOrganizationAccess,
    organizationOptions,
    products,
  ]);

  /**
   * Generates filters
   */
  const treatmentFilter = useMemo(() => {
    let order = '';
    let direction = '';
    let searchQuery = '';
    let isAnonymous = false;
    const orgId = hasOrganizationAccess
      ? treatmentFilters?.organizationId?.value || ''
      : organizationId;
    const deviceName = treatmentFilters?.device_name?.value || '';
    const treatmentType =
      treatmentFilters?.treatment_type?.value.toLowerCase() || '';

    const totalSessions = getTransformedLogicTableFilterValue(
      treatmentFilters,
      'sessions',
    );

    const startDate = getTransformedLogicTableFilterValue(
      treatmentFilters,
      'start_date',
    );

    switch (currentSort) {
      case 'up':
        direction = 'DESC';
        break;
      case 'down':
        direction = 'ASC';
        break;
      default:
        break;
    }

    if (currentSort && currentSortType && direction !== 'default')
      order = `${currentSortType} ${direction}`;

    if (debouncedSearchTerm) {
      searchQuery = `${debouncedSearchTerm.toLowerCase()}`;
      if (t('treatments.anon-client-name').toLowerCase().includes(searchQuery))
        isAnonymous = true;
    }

    return {
      page: page * limit || 0,
      limit,
      order,
      orgId,
      searchQuery,
      isAnonymous,
      deviceName,
      treatmentType,
      totalSessions,
      startDate,
    };
  }, [
    t,
    page,
    limit,
    currentSort,
    currentSortType,
    hasOrganizationAccess,
    organizationId,
    debouncedSearchTerm,
    treatmentFilters,
  ]);

  /**
   * Gets all Treatments on filters changes
   */
  useEffect(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      orgId,
      deviceName,
      treatmentType,
      totalSessions,
      startDate,
    } = treatmentFilter;
    getTreatmentsOverview(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      isAnonymous,
      deviceName,
      treatmentType,
      totalSessions,
      startDate,
    );
  }, [getTreatmentsOverview, treatmentFilter]);

  /**
   * Refresh teams data
   */
  const refreshCurrentData = useCallback(() => {
    const {
      page,
      limit,
      order,
      searchQuery,
      isAnonymous,
      orgId,
      deviceName,
      treatmentType,
      totalSessions,
      startDate,
    } = treatmentFilter;
    getTreatmentsOverview(
      page,
      limit,
      order,
      orgId,
      searchQuery,
      isAnonymous,
      deviceName,
      treatmentType,
      totalSessions,
      startDate,
    );
  }, [getTreatmentsOverview, treatmentFilter]);

  /**
   * Close delete modal
   */
  const onCloseDeleteModal = useCallback(() => setDeleteModalIsOpen(false), []);

  /**
   * Open delete modal
   */
  const onOpenDeleteModal = useCallback((id, isRemoteConfig) => {
    setTreatmentToDelete({ id, isRemoteConfig });
    setDeleteModalIsOpen(true);
  }, []);

  /**
   * Delete treatment handler
   */
  const handleDeleteTreatment = useCallback(async () => {
    await deleteTreatmentById(treatmentToDelete?.id);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteTreatmentById, refreshCurrentData, treatmentToDelete?.id]);

  /**
   * Delete remote treatment handler
   */
  const handleDeleteRemoteTreatment = useCallback(async () => {
    await deleteRemoteTreatmentById(treatmentToDelete?.id);
    refreshCurrentData();
    setDeleteModalIsOpen(false);
  }, [deleteRemoteTreatmentById, refreshCurrentData, treatmentToDelete?.id]);

  const renderCreateTreatmentButton = useMemo(
    () => !!practitioner,
    [practitioner],
  );

  /**
   * Checks treatment session, sets treatment data and open create / continue session modal
   * @param event native event
   * @param generatedUUID treatment id
   * @param clientName client's name
   */
  const checkSessionsAndOpenModal = async (
    event,
    generatedUUID,
    clientName,
  ) => {
    event.stopPropagation();
    const sessions = await sessionService.getAllSessionsByTreatmentUUID(
      generatedUUID,
    );
    const currentDate = moment().toDate();
    const dateAllowance = moment(
      new Date().setHours(currentDate.getHours() - 2),
    );

    const filteredSessions = sessions.filter(
      (item) =>
        item.start_date &&
        moment(item.start_date).isBetween(dateAllowance, currentDate),
    );
    const latestSession = filteredSessions[0] || {};

    setSessionUUID(latestSession.sessionUUID);
    setClientName(` ${clientName}`);
    setTreatmentUUID(generatedUUID);
    setOpenModal(true);
  };

  /**
   * Remder talbe actions
   */
  const renderActions = useCallback(
    (value, tableMeta, updateValue) => {
      const [generatedUUIDWrapper, client_name, idWrapper, typeData] =
        tableMeta.rowData;
      const generatedUUID = generatedUUIDWrapper?.props?.children;
      const hasActiveProduct = tableMeta.rowData[9];
      const type = typeData?.props?.children?.props?.type || '';
      const clientName = client_name?.props?.children?.props?.clientName || '';
      const id = idWrapper?.props?.children;

      const isRemoteConfig = type === 'EMDR-Remote';
      return (
        <CustomMuiDataTableBodyButton
          firstButtonIcon={<PlayIcon />}
          secondButtonIcon={<DeleteIcon />}
          isPrimary={isRemoteConfig && hasActiveProduct}
          isPrimaryDisabled={!generatedUUID}
          onPrimaryActionButton={(e) =>
            checkSessionsAndOpenModal(
              e,
              generatedUUID,
              translateClientName(clientName),
            )
          }
          onSecondaryActionButton={() => onOpenDeleteModal(id, isRemoteConfig)}
        />
      );
    },
    [onOpenDeleteModal],
  );

  /**
   * Generates export CSV data
   */
  const exportData = useMemo(() => {
    const dataToUse = treatmentsData || [];

    return dataToUse.map((item) => {
      const date = item.start_date;
      return {
        generatedUUID: item.generated_uuid,
        [t('forms.client_name')]: translateClientName(item.name),
        id: item.id,
        [t('forms.type')]: item.type,
        [t('forms.total.sessions')]: item.sessions,
        [t('forms.device')]: item.device_name || 'null',
        [t('forms.start_date')]: date
          ? moment(date).format('DD-MM-YYYY')
          : 'null',
        device_id: item.device_id,
      };
    });
  }, [t, treatmentsData]);

  /**
   * Generates table rows
   */
  const createPresentableRows = useMemo(() => {
    const dataToUse = treatmentsData || [];

    return dataToUse.map((item) => {
      const date = item.start_date;
      return {
        generatedUUID: (
          <DataCell type='secondary'>{item.generated_uuid}</DataCell>
        ),
        [t('forms.client_name')]: (
          <DataCell>
            <ClientAvatar
              clientName={translateClientName(item.name)}
              seedId={item.generated_uuid}
            />
          </DataCell>
        ),
        id: <DataCell type='numerics'>{item.id}</DataCell>,
        [t('forms.type')]: (
          <DataCell type='secondary'>
            <CustomMuiDatatableEmdrType type={item.type} />
          </DataCell>
        ),
        [t('forms.total.sessions')]: (
          <DataCell type='numerics'>{item.sessions}</DataCell>
        ),
        [t('forms.device')]: (
          <DataCell type='secondary'>{item.device_name}</DataCell>
        ),
        [t('forms.start_date')]: (
          <DataCell type='secondary'>
            <CustomDateTimeLayout date={date} />
          </DataCell>
        ),
        client_id: <DataCell type='numerics'>{item.client_id}</DataCell>,
        device_id: <DataCell type='numerics'>{item.device_id}</DataCell>,
        hasActiveProduct: item.hasActiveProduct,
      };
    });
  }, [t, treatmentsData]);

  /**
   * Sort handler
   */
  const handleSort = useCallback(
    (type) => {
      let nextSort;
      if (currentSort === 'down') nextSort = 'up';
      else if (currentSort === 'up') nextSort = 'default';
      else if (currentSort === 'default') nextSort = 'down';

      setCurrentSortType(type);
      setCurrentSort(nextSort);
    },
    [currentSort],
  );

  /**
   * Render talbe haed
   */
  const renderHead = useCallback(
    (mappedSortTypes, key) => {
      const sortType = mappedSortTypes[key];
      return (
        <CustomDateSortHeader
          key={`sort-header-${key}`}
          currentSort={currentSortType === sortType ? currentSort : 'default'}
          label={key}
          type={sortType}
          handleSort={handleSort}
        />
      );
    },
    [currentSort, currentSortType, handleSort],
  );

  /**
   * Generates table attributes
   */
  const createTableAttributes = useMemo(() => {
    let attributesToUse = [];
    const mappedSortTypes = {
      [t('forms.start_date')]: 'start_date',
      [t('forms.type')]: 'type',
      [t('forms.client_name')]: 'name',
      [t('forms.device')]: 'device_name',
      [t('forms.total.sessions')]: 'sessions',
    };
    if (createPresentableRows && createPresentableRows.length > 0) {
      const keysNotToShow = ['id', 'device_id', 'generatedUUID'];
      const excludedKeys = ['client_id', 'hasActiveProduct'];
      attributesToUse = Object.keys(createPresentableRows[0]).map((key) => {
        if (keysNotToShow.includes(key))
          return { name: key, options: { display: false, sort: false } };

        if (excludedKeys.includes(key))
          return { name: key, options: { display: 'excluded' } };

        if (mappedSortTypes[key]) {
          return {
            name: key,
            options: {
              customHeadRender: () => renderHead(mappedSortTypes, key),
            },
          };
        }
        return {
          name: key,
          options: { sort: false },
        };
      });
      attributesToUse.push({
        name: '',
        options: {
          filter: false,
          sort: false,
          empty: true,
          setCellProps: (value) => ({
            className: 'action',
          }),
          customBodyRender: (value, tableMeta, updateValue) =>
            renderActions(value, tableMeta, updateValue),
        },
      });
    }
    return attributesToUse;
  }, [createPresentableRows, t, renderActions, renderHead]);

  /**
   * Init table's options
   */
  const options = useCallback(
    () => ({
      serverSide: true,
      page,
      rowsPerPage: limit,
      count: totalTreatments,
      sort: true,
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const { page: offset, limit } = treatmentFilter;
        const showedRows = offset + limit;
        const pagesLimit = Math.ceil(totalTreatments / numberOfRows);
        if (showedRows <= numberOfRows && page !== 0) {
          const newPage = Math.floor(numberOfRows / showedRows) - 1;
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        } else if (showedRows > numberOfRows) {
          const newPage = Math.floor(showedRows / numberOfRows);
          if (newPage >= pagesLimit) setPage(pagesLimit - 1);
          else setPage(newPage);
        }
        setLimit(numberOfRows);
      },
      onSearchChange: (text) => setSearchText(text),
      searchText,
      searchAlwaysOpen: true,
    }),
    [page, limit, totalTreatments, searchText, treatmentFilter],
  );

  /**
   * Generates the table filter's config
   */
  const filtersConfig = useTableFilterConfig('treatment');

  /**
   * Closes the filter's modal and save data for the filters
   */
  const onSaveFilters = useCallback(
    (filters) => setTreatmentFilters(filters),
    [],
  );

  /**
   * Checks does table have data
   */
  const isDataTableEmpty = createPresentableRows.length <= 0;

  /**
   * Flag to checks does filters active
   */
  const isFilters = Object.keys(treatmentFilters || {})?.length > 0;

  /**
   * mui datatable optional class names
   */
  const muiTableMods = {
    loading: isTableLoading,
    'no-data': isDataTableEmpty,
    filters: isFilters,
  };

  /**
   * Reset page for search if it's not the first page and there is no results
   */
  useEffect(() => {
    const pagesLimit = Math.ceil(totalTreatments / limit);
    if (page > pagesLimit && debouncedSearchTerm) setPage(0);
  }, [debouncedSearchTerm, limit, page, totalTreatments]);

  /**
   * Reset page for existings filters if it's not the first page and there is no results
   */
  useEffect(() => {
    if (isFilters && createPresentableRows.length <= 0 && page > 0) setPage(0);
  }, [createPresentableRows, isFilters, page]);

  /**
   * Clears treatments on leaving the page
   */
  useEffect(
    () => () => {
      clearTreatments();
    },
    [],
  );

  return (
    <>
      <TableHeader
        buttonTitle='buttons.create.treatment'
        isButton={renderCreateTreatmentButton}
        title='treatments.title'
        subTitle=''
        onClickCallback={() => history.push('/admin/treatments/create')}
        btnIcon={<AddTreatmentIcon className='treatment-icon' />}
      />
      <Box className='table-container'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={getMuiTheme(
              {},
              {
                '&:nth-of-type(1)': {
                  width: 250,
                },
                '&:nth-of-type(2)': {
                  width: 200,
                },
                '&:nth-of-type(3)': {
                  width: 250,
                },
                '&:nth-of-type(4)': {
                  width: 150,
                },
                '&:nth-of-type(5)': {
                  width: 250,
                },
                '&:nth-of-type(6)': {
                  width: 100,
                },
              },
            )}
          >
            <MUIDataTable
              ref={tableRef}
              data={
                isTableLoading
                  ? muiTableLoadingSkeleton('body')
                  : createPresentableRows
              }
              columns={
                isTableLoading
                  ? muiTableLoadingSkeleton('header')
                  : createTableAttributes
              }
              options={tableConfig(
                options(),
                isTableLoading,
                isDataTableEmpty,
                tableRef,
                isFilters,
                onOpenFilterModal,
                exportData,
              )}
              components={muiComponents(treatmentFilters, setTreatmentFilters)}
              className={classNames('mui-data-table search-open', muiTableMods)}
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {/* Continue / create session modal */}
        {openModal && (
          <CreateContinueSessionModal
            open={openModal}
            setOpen={setOpenModal}
            clientName={clientName}
            treatmentUUID={treatmentUUID}
            sessionUUID={sessionUUID}
          />
        )}
        {/* Delete confirmation modal */}
        <ModalUI
          modalSx={deleteConfirmation}
          open={deleteModalIsOpen}
          isCloseBtn={false}
          onCloseHandler={onCloseDeleteModal}
          className='confirmation-modal'
          modalTitle={t('alerts.titles.delete')}
        >
          <SimpleModalContent
            content={['alerts.confirm.treatments']}
            mainBtn='buttons.delete'
            onMainBtnClick={
              treatmentToDelete?.isRemoteConfig
                ? handleDeleteRemoteTreatment
                : handleDeleteTreatment
            }
            secondaryBtn='buttons.cancel'
            onSecondaryBtnClick={onCloseDeleteModal}
          />
        </ModalUI>
        {/* Filter modal */}
        {filterOpen && (
          <CustomMuiDataTableFilter
            isOpen={filterOpen}
            setFilterState={setFilterOpen}
            config={filtersConfig}
            filters={treatmentFilters}
            onSaveHandler={onSaveFilters}
          />
        )}
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getTreatmentsOverview: treatmentActions.getTreatmentsOverview,
  getAllOrganizations: organizationActions.getShortOrganizationsWithoutMetadata,
  deleteTreatmentById: treatmentActions.deleteDeviceTreatmentConfiguration,
  deleteRemoteTreatmentById: treatmentRemoteActions.deleteRemoteTreatmentById,
  clearTreatments: treatmentActions.clearTreatments,
  getAllProductConfigurations: productActions.getAllProductConfigurations,
  getAllDevicesNames: deviceActions.getAllDevicesNames,
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentsTable);
