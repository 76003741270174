import { laptopRatio, tabletRatio, theme } from '../../../../theme';
import { getResponsiveSize } from '../../../../utils';

export const therapyLocationStyles = {
  therapyLocation: {
    '& .checkbox-card-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      gap: getResponsiveSize(57), // 57px
      '& .column': {
        boxShadow: 'none',
        '&.checked': {
          boxShadow: `0px ${getResponsiveSize(5)} ${getResponsiveSize(
            20,
          )} rgba(0, 0, 0, 0.05)`,
        },
        '.MuiCardActionArea-root .icon svg': {
          '&:first-of-type': {
            width: getResponsiveSize(131), // 131px
            height: getResponsiveSize(107), // 107px
          },
          '&:last-of-type': {
            width: getResponsiveSize(118), // 118px
            height: getResponsiveSize(119), // 119px
          },
        },
      },
    },
    // Laptop screen
    [theme.breakpoints.media.laptop]: {
      '& .checkbox-card-wrapper': {
        gap: getResponsiveSize(45, laptopRatio), // 45px
        '& .column': {
          width: getResponsiveSize(340, laptopRatio), // 340px
        },
      },
    },
    // Tablet screen
    [theme.breakpoints.media.tablet]: {
      '& .checkbox-card-wrapper': {
        flexDirection: 'column',
        gap: getResponsiveSize(25, tabletRatio), // 25px
        '& .column': {
          width: getResponsiveSize(440, tabletRatio), // 440px
          '.MuiCardActionArea-root': {
            '& .icon svg': {
              mr: getResponsiveSize(20, tabletRatio), // 16px
              '&:first-of-type': {
                width: getResponsiveSize(105, tabletRatio), // 131px
                height: getResponsiveSize(80, tabletRatio), // 107px
              },
              '&:last-of-type': {
                width: getResponsiveSize(92, tabletRatio), // 92px
                height: getResponsiveSize(92, tabletRatio), // 92px
              },
            },
            '& .MuiCardContent-root': {
              gap: getResponsiveSize(4, tabletRatio), // 4px
              '& .card-title ': {
                fontSize: getResponsiveSize(16, tabletRatio), // 16px
              },
              '& .desc': {
                fontSize: getResponsiveSize(14, tabletRatio), // 14px
                lineHeight: 1.5,
              },
            },
          },
        },
      },
    },
  },
};
