import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import MuiInput from '@mui/material/Input';

import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { useCallbackDebounce } from '../../../hooks/useCallbackDebounce';

import { classNames } from '../../../utils';
import { WizardError } from '../../Treatments/CreateTreatmentWizard/WizardFooter/WizardError/WizardError';
import { CustomSlider, rangeInptuStyle } from './style';

export default function RangeInput(props) {
  const {
    rangeValue,
    step,
    min,
    max,
    name,
    marks,
    inputValue,
    onSliderChangeHandler,
    onInputChangeHandler,
    onSliderChangeCommittedHandler,
    isPercentage = false,
    isRange = false,
  } = props;
  const { rangeInputWrapper, inputStyle } = rangeInptuStyle;
  const { t } = useTranslation();
  const [displayValue, setDisplayValue] = useState(isRange ? [min, max] : min);
  const [prevValidDisplayValue, setPrevValidDisplayValue] =
    useState(inputValue);
  const [isError, setIsError] = useState(false);

  /**
   * Handle range slider change
   * @param event native event
   * @param newValue new selected value
   */
  const handleSliderChange = useCallback(
    (event, newValue) => {
      setDisplayValue(newValue);
      if (!isRange) setPrevValidDisplayValue(newValue);
    },
    [isRange],
  );

  /**
   * Shows an error
   */
  const showError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };

  /**
   * Validate input data
   * @param value input data
   */
  const validateInputData = (value) => {
    if (value > max || value < min) {
      showError();
      setDisplayValue(parseFloat(prevValidDisplayValue));
      return;
    }
    setPrevValidDisplayValue(value);
    onSliderChangeCommittedHandler({
      variable: name,
      value:
        value === ''
          ? ''
          : isPercentage
          ? (value / 100).toFixed(2)
          : Number(value),
    });
  };

  /**
   * Debounced input change handler
   */
  const debouncedInputHandler = useCallbackDebounce(validateInputData, 500);

  /**
   * Handle input changes and debounced call input validation
   * @param event native event
   */
  const handleInputChange = (event) => {
    setDisplayValue(
      event.target.value === '' ? '' : Number(event.target.value),
    );
    debouncedInputHandler(+event.target.value);
  };

  const clickableMarks = useMemo(
    () =>
      marks.map((item, index) => {
        if (!item?.label) return item;

        return {
          value: item.value,
          lebel: '',
          element: (
            <Box
              sx={{ left: `${(item.value - min / (max - min)) * 100 - 8}%` }}
              component='span'
              className={classNames(
                'mark-label',
                { first: index === 0, last: index === marks.length - 1 },
                [],
              )}
              key={`${index}-${item.value}`}
              onClick={(e) => {
                e.preventDefault();
                setDisplayValue(item.value);
                debouncedInputHandler(+item.value);
              }}
            >
              {item.label}
            </Box>
          ),
        };
      }),
    [debouncedInputHandler, marks, max, min],
  );

  useEffect(() => {
    if (isRange && Array.isArray(rangeValue)) {
      setDisplayValue(rangeValue);
    } else {
      if (rangeValue || rangeValue == 0)
        setDisplayValue(
          isPercentage ? parseFloat(rangeValue * 100) : parseFloat(rangeValue),
        );
      if (inputValue || rangeValue == 0)
        setDisplayValue(
          isPercentage ? parseFloat(rangeValue * 100) : parseFloat(rangeValue),
        );
    }
  }, [rangeValue, inputValue, isPercentage, isRange]);

  useEffect(() => {}, []);

  return (
    <Box sx={rangeInputWrapper} className='range-slider'>
      <CustomSlider
        valueLabelDisplay='auto'
        value={displayValue || 0}
        onChange={
          onSliderChangeHandler ? onSliderChangeHandler : handleSliderChange
        }
        onChangeCommitted={() =>
          onSliderChangeCommittedHandler({
            variable: name,
            value: isPercentage
              ? (displayValue / 100).toFixed(2)
              : displayValue,
          })
        }
        aria-labelledby='input-slider'
        min={min}
        max={max}
        marks={clickableMarks}
        name={name}
        step={step}
      />
      <Box className='slider-labels'>
        {clickableMarks.map((item) => item.element)}
      </Box>

      {!isRange && (
        <Box className='error-wrapper'>
          <Transition
            in={isError}
            timeout={300}
            mountOnEnter
            unmountOnExit
            appear={true}
          >
            {(state) => (
              <WizardError
                className={classNames('error', {}, [
                  'visible',
                  'slide-top',
                  `slide-in-${state}`,
                ])}
              >
                {t('errors.validation.range_input', { min, max })}
              </WizardError>
            )}
          </Transition>

          <MuiInput
            value={displayValue}
            size='small'
            name={name}
            onChange={
              onInputChangeHandler ? onInputChangeHandler : handleInputChange
            }
            sx={inputStyle}
            inputProps={{
              step: { step },
              min: { min },
              max: { max },
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Box>
      )}
    </Box>
  );
}
