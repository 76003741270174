import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';

import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { useActions } from '../../../../hooks/useActions';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { REMOTE_THERAPY, THERAPY_ROOM } from '../../../../utils/config';
import CheckboxCard from '../../../UI/CheckboxCard/CheckboxCard';
import { WizardContentBox } from '../../../UI/WizardContentBox/WizardContentBox';

import { useFetchTherapyType } from '../hooks/useFetchTherapyType';
import { useWizardTherapyError } from '../hooks/useWizardTherapyError';
import { therapyLocationStyles } from './style';

function TherapyLocation(props) {
  const { treatmentsRemote, t } = props;
  const { remoteTreatmentUUID } = treatmentsRemote;
  const { therapyLocation } = therapyLocationStyles;
  const { location, canGoNext } = useSelector(
    (state) => state.createTreatmentWizard,
  );
  const { setLocation, setNextButton, setNotification } = useActions();
  const [copiedText, copy] = useCopyToClipboard();
  const [therapyType, setTherapyType] = useFetchTherapyType();


  // TODO: remove
  /**
   * Checks does treatment has been created
   */
  const isTreatmentCreated = useMemo(
    () => (remoteTreatmentUUID ? true : false),
    [remoteTreatmentUUID],
  );

  /**
   * Handle therapy type changed
   * @param checkbox selected checkbox data
   */
  const handleTherapyTypeSelect = useCallback(
    (checkbox) => {
      switch (checkbox.type) {
        case THERAPY_ROOM:
          setLocation(THERAPY_ROOM);
          break;
        case REMOTE_THERAPY:
          setLocation(REMOTE_THERAPY);
          break;
      }
    },
    [setLocation],
  );

  /**
   * Set error if therapy type isn't selected
   */
  useWizardTherapyError(isTreatmentCreated, copiedText);

  /**
   * Set next button if location previously was selected
   */
  useEffect(() => {
    if (location) if (!canGoNext) setNextButton(true);
  }, [canGoNext, setNextButton]);

  return (
    <Box sx={therapyLocation} className='step-container'>
      <WizardContentBox active={!isTreatmentCreated}>
        <Typography variant='h3' className='wizard-box-title'>
          {t('create-treatment-wizard.select-therapy-type.title')}
        </Typography>
        <Box className='checkbox-card-wrapper'>
          {therapyType &&
            Object.values(therapyType).map((therapy) => (
              <CheckboxCard
                type='column'
                disabled={isTreatmentCreated}
                key={therapy.type}
                checkboxItem={therapy}
                onCardSelectHandler={handleTherapyTypeSelect}
              />
            ))}
        </Box>
      </WizardContentBox>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withTranslation()(connect(mapStateToProps)(TherapyLocation));
