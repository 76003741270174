import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useAdminConfig from '../hooks/useAdminConfig';

const AdminPage = () => {
  const {
    isLoading,
    adminConfig,
    updateTakeBillingIntoAccount,
    updateEnableBilling,
    getAdminConfig,
  } = useAdminConfig();

  useEffect(() => {
    getAdminConfig();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!adminConfig) {
    return <div>No admin configuration available...</div>;
  }

  return (
    <div>
      <div className='organizations-wrapper'>
        <div className='title'>
          Admin page
          <div className='metadata-wrapper'>
            <p className='title-description' />
          </div>
        </div>
        <div
          className='bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3'
          role='alert'
        >
          <p className='font-bold'>This is the admin only overview.</p>
          <p className='text-sm'>I hope you know what you're doing.</p>
        </div>
        <div
          style={{
            height: '200px',
            display: 'flex',
            width: '40%',
            margin: '0 auto',
            gap: '32px',
          }}
        >
          <button
            className='w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 text-4xl'
            onClick={updateEnableBilling}
          >
            {adminConfig?.enableBilling
              ? 'Disable payments'
              : 'Enable payments'}
          </button>
          <button
            className='w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 text-4xl'
            onClick={updateTakeBillingIntoAccount}
          >
            {adminConfig?.takeBillingIntoAccount
              ? 'Stop taking billing into account'
              : 'Take billing into account'}
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AdminPage),
);
