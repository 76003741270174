import { useState } from 'react';
import { adminService } from '../services/admin';

const useAdminConfig = () => {
  const [adminConfig, setAdminConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isBillingEnabled = adminConfig?.enableBilling;
  const takeBillingIntoAccount = adminConfig?.takeBillingIntoAccount;

  const getAdminConfig = async () => {
    setIsLoading(true);
    const result = await adminService.getFirstAdminConfig();
    setAdminConfig(result);
    setIsLoading(false);
  };

  const handleUpdateAdminConfig = async (data) => {
    setIsLoading(true);
    await adminService.patchAdminConfig(adminConfig?.id, data);
    await getAdminConfig();
    setIsLoading(false);
  };

  const updateTakeBillingIntoAccount = async () => {
    await handleUpdateAdminConfig({
      takeBillingIntoAccount: !takeBillingIntoAccount,
    });
  };

  const updateEnableBilling = async () => {
    await handleUpdateAdminConfig({
      enableBilling: !isBillingEnabled,
    });
  };

  return {
    isBillingEnabled,
    takeBillingIntoAccount,
    isLoading,
    adminConfig,
    getAdminConfig,
    updateTakeBillingIntoAccount,
    updateEnableBilling,
  };
};

export default useAdminConfig;
