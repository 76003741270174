import { treatmentConstants } from '../constants/treatment.constants';

export function treatments(
  state = {
    deviceTreatmentConnectionId: null,
    totalTreatments: 0,
  },
  action,
) {
  switch (action.type) {
    case treatmentConstants.GETALL_REQUEST:
      return {
        ...state,
        isLoading: true,
        deviceTreatmentConnectionId: null,
        deviceTreatmentConnection: null,
        isReceived: true,
        isNotConnected: false,
      };
    case treatmentConstants.GETALL_SUCCESS: {
      const { attributes, data, totalCount } = action.treatments;
      return {
        ...state,
        columns: attributes,
        data,
        totalTreatments: totalCount,
        isLoading: false,
        deviceTreatmentConnectionId: null,
        deviceTreatmentConnection: null,
        isReceived: true,
        isNotConnected: false,
      };
    }
    case treatmentConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        deviceTreatmentConnectionId: null,
        deviceTreatmentConnection: null,
        isReceived: true,
        isNotConnected: false,
      };
    case treatmentConstants.CREATE_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case treatmentConstants.CREATE_SUCCESS:
      const treatments = state.treatments || [];
      treatments.push(action.practitioner);
      return {
        ...state,
        treatments,
        isLoading: false,
      };
    case treatmentConstants.CREATE_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case treatmentConstants.GET_DEVICE_CONNECTION_STATUS:
      const { id } = action;

      return {
        ...state,
        isReceived: false,
        deviceTreatmentConnectionId: id,
      };
    case treatmentConstants.GET_DEVICE_CONNECTION_STATUS_SUCCESS: {
      const { data } = action;
      const { received } = data;

      return {
        ...state,
        deviceTreatmentConnection: data,
        isReceived: received,
      };
    }
    case treatmentConstants.GET_DEVICE_CONNECTION_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case treatmentConstants.RESET_DEVICE_TREATMENT_CONNECTION_ID:
      return {
        ...state,
        isReceived: false,
        deviceTreatmentConnectionId: null,
      };
    case treatmentConstants.CREATE_CONFIG_SUCCESS:
      const { config } = action;
      return {
        ...state,
        isReceived: false,
        deviceTreatmentConnectionId: config.id,
      };
    case treatmentConstants.USE_DEFAULT_DEVICE_CONFIGURATION:
      return {
        ...state,
        isReceived: true,
        isNotConnected: true,
      };
    case treatmentConstants.PATCH_CONFIG_SUCCESS:
      return {
        ...state,
        isReceived: false,
        isNotConnected: false,
        deviceTreatmentConnectionId: null,
        deviceTreatmentConnection: null,
      };
    case treatmentConstants.DELETE_REQUEST:
      return {
        ...state,
      };
    case treatmentConstants.DELETE_SUCCESS: {
      const { id } = action;
      const { data } = state;
      const filteredTreatments = data.filter((item) => item.id !== id);
      return {
        ...state,
        data: filteredTreatments,
      };
    }
    case treatmentConstants.DELETE_FAILURE:
      return {
        ...state,
        error: true,
      };
    case treatmentConstants.RESET_STATE:
      return {
        ...state,
        deviceTreatmentConnectionId: null,
        deviceTreatmentConnection: null,
        isLoading: false,
      };
    case treatmentConstants.CLEAR_TREATMENTS:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}
