import { Box, Button, Typography } from '@mui/material';
import { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';

import { classNames, getResponsiveSize } from '../../../utils';
import SelectUI from '../Select/Select';
import { combineToggleStyles } from './style';
import { mapIconsNameToIcon } from '../../../utils/wizard-helper';

function CombineToggle(props) {
  const {
    className,
    name,
    selectValue,
    selectedOption,
    options,
    width = getResponsiveSize(325), // 325px
    onChangeHandler,
    getVariableTranslation,
    t,
    optionIcon = false,
  } = props;
  const { combineToggle } = combineToggleStyles;
  const [displayValue, setDisplayValue] = useState(selectValue);
  const dropdownValue = useRef(selectedOption);

  /**
   * Handle button toggle and set selected value
   * @param optionName selected toggle variable name
   */
  const handleBtnToggle = useCallback(
    (optionName) => {
      setDisplayValue(optionName === name);
      options.forEach((option) => {
        onChangeHandler({
          variable: option.variable,
          value:
            option.type === 'text'
              ? optionName === option.variable
              : dropdownValue.current,
        });
      });
    },
    [name, options, setDisplayValue, onChangeHandler],
  );

  /**
   * Handle select dropdown change event and set selected value
   * @param value selected option value
   */
  const onSelectChange = useCallback(
    (value) => {
      dropdownValue.current = value.value;
      handleBtnToggle(value.variable);
    },
    [handleBtnToggle],
  );

  /**
   * Render node for text button toggle
   * @param option option data
   */
  const renderTextButton = useCallback(
    (option) => (
      <Button
        key={option?.variable}
        disableRipple
        variant='text'
        className={classNames(option?.variable, {
          active: name === option.variable ? displayValue : !displayValue,
        })}
        onClick={() => handleBtnToggle(option?.variable)}
      >
        {t(option.content)}
      </Button>
    ),
    [name, displayValue, t, handleBtnToggle],
  );

  /**
   * Render node for dropdown toggle
   * @param option option data
   */
  const renderDropdown = useCallback(
    (option) => {
      const dropdownOptions = option?.dropdownOptions.map((option) => ({
        text: option,
        value: option,
        ...(optionIcon && { icon: mapIconsNameToIcon(`${option}-ball`) }),
      }));

      return (
        <Box
          onClick={() =>
            !displayValue ? handleBtnToggle(option?.variable) : null
          }
          key={option?.variable}
          className={classNames('dropdown-wrapper', {
            active: name === option?.variable ? displayValue : !displayValue,
          })}
        >
          <Typography variant='h5' className='dropdown-title'>
            {getVariableTranslation(option?.variable)}
          </Typography>
          <SelectUI
            disabled={!displayValue}
            idPrefix={option?.variable}
            size='medium'
            name={option?.variable}
            selectValue={selectedOption}
            onChangeHandler={onSelectChange}
            options={dropdownOptions}
          />
        </Box>
      );
    },
    [
      name,
      displayValue,
      getVariableTranslation,
      selectedOption,
      onSelectChange,
      optionIcon,
      handleBtnToggle,
    ],
  );

  /**
   * Generate toggle options node
   */
  const toggleOptions = useMemo(
    () =>
      options?.map((option) => {
        switch (option?.type) {
          case 'text':
            return renderTextButton(option);
          case 'dropdown':
            return renderDropdown(option);
        }
      }),
    [options, renderTextButton, renderDropdown],
  );

  useEffect(() => {
    dropdownValue.current = selectedOption;
    setDisplayValue(selectValue);
  }, [selectValue, selectedOption]);

  return (
    <Box
      sx={{ ...combineToggle, width }}
      className={classNames('toggle', {}, [className])}
    >
      <Box className='mask-box'>
        <Box className={classNames('mask', { right: !displayValue })} />
        {options ? toggleOptions : null}
      </Box>
    </Box>
  );
}

export default withTranslation()(memo(CombineToggle));
